import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import * as AlertStore from '../../store/Alert';
import { Page } from '../../shared/components/Page';
import { ConnectedPasswordForm } from '../../shared/components/PasswordForm';
import * as CommonStore from '../../store/CommonForm';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { continueLogin } from '../../shared/utils/continue-login';

type PasswordResetProps = {
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const PasswordReset = (props: PasswordResetProps) => {
  const params = new URLSearchParams(window.location.search);
  const userSub = params.get('userSub') || '';
  const verificationCode = params.get('securityCode') || '';
  const returnUrl = params.get('returnUrl' || '');
  const username = decodeURIComponent(params.get('username') || '');

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/password/reset',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload) {
          if (payload.isSpaRoute) {
            props.replace(payload.redirectUrl);
          } else {
            window.location.replace(payload.redirectUrl);
          }
        } else {
          continueLogin(
            props.setIsSubmitting,
            props.replace,
            props.setAlertMessage,
            props.clearAlertMessage
          );
        }
      })
      .catch(() => {});
  };

  return (
    <Page paperSize="lg">
      <Typography variant={TypographyVariants.H5} paragraph>
        RESET PASSWORD
      </Typography>
      <ConnectedPasswordForm
        hideReturnButton
        onSubmit={onSubmit}
        initialValues={{ userSub, returnUrl, verificationCode, username }}
        enableReinitialize
      />
    </Page>
  );
};

function mapStateToProps(state) {
  return { ...state.commonForm, ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const PasswordResetPage = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordReset);
