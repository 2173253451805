import React from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@dealersocket/ds-ui-react/Button';
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  required,
  validEmail,
  validEmailCustom,
} from '../../shared/utils/validation';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { useAxios } from '../../shared/utils/use-axios';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import { ChevronRightCircleIcon } from '../../shared/icons/chevron-right-circle-icon';
import * as CommonStore from '../../store/Common';

const styles = (theme: Theme): StyleRules => {
  return {
    buttonStyle: {
      whiteSpace: 'nowrap',
      marginLeft: 12,
      padding: '10px 40px',
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    formGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    buttonGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    textFieldIcon: { marginRight: 5 },
    skipCountdown: {
      float: 'right',
      '& span': {
        margin: 3,
      },
    },
    skipCountdownMessage: {
      display: 'flex',
      alignItems: 'center',
    },
    skipCountdownWarn: {
      color: 'red',
      display: 'inline-flex',
      alignItems: 'center',
    },
    skipCountdownWarnIcon: {
      marginRight: 3,
    },
  };
};

type UsernameUpdateProps = {
  isSubmitting: boolean;
  push: any;
  replace: any;
  isValidEmail: boolean;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

type InternalProps = {
  classes: JssClasses;
};

function UsernameUpdate(props: UsernameUpdateProps & InternalProps) {
  const { isSubmitting, classes, isValidEmail, webPSupported } = props;

  const [result, busy] = useAxios('/accountSetup/getUsernameUpdate', undefined);

  const skipUsernameUpdate = () => {
    const body = {};

    axiosHelper(
      '/accountSetup/skipUsernameUpdate',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  const onSubmit = (values: any): any => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/accountSetup/updateUsername',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      undefined,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.push(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  return (
    <Page>
      <Typography
        data-e2e="usernameToEmailTitle"
        variant={TypographyVariants.H5}
        paragraph
      >
        Ready to streamline your login process?
      </Typography>
      <Typography variant={TypographyVariants.Subtitle1} paragraph>
        Type the email address that you will use to log in to DealerSocket.
      </Typography>
      {result.daysLeft > 0 && (
        <Typography variant={TypographyVariants.Subtitle2} paragraph>
          Click <strong>Not Now</strong> to skip this process for{' '}
          {result.daysLeft} {result.daysLeft > 1 ? 'days' : 'day'}.
        </Typography>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: result.email }}
        enableReinitialize
      >
        <FormGroup className={classes.formGroupStyle}>
          <FormTextField
            autoComplete="email"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="email"
            startAdornment={<PersonIcon className={classes.textFieldIcon} />}
            autoFocus
            required
            placeholder="Email"
            disabled={isSubmitting || busy}
            fullWidth={webPSupported}
            validate={[
              required,
              validEmailCustom('Please enter a valid email address'),
            ]}
          />
          <Button
            data-e2e="submitButton"
            disabled={isSubmitting || busy || !isValidEmail}
            type="submit"
            color="primary"
            className={classes.buttonStyle}
          >
            Continue
          </Button>
        </FormGroup>
        <FormGroup className={classes.buttonGroupStyle}>
          <Button
            data-e2e="returnToLoginButton"
            disabled={isSubmitting}
            onClick={cancel}
            className={classes.secondaryButtonStyle}
          >
            <ChevronLeftCircleIcon />
            Return To Login
          </Button>

          {result.daysLeft > 0 ? (
            <Button
              data-e2e="skipButton"
              disabled={isSubmitting}
              onClick={skipUsernameUpdate}
              className={classes.secondaryButtonStyle}
            >
              Not Now
              <ChevronRightCircleIcon />
            </Button>
          ) : null}
        </FormGroup>
      </Form>
      {result.daysLeft > 0 && (
        <div className={classes.skipCountdown}>
          <Typography
            variant={TypographyVariants.Subtitle2}
            paragraph
            className={classes.skipCountdownMessage}
          >
            <strong>
              {result.daysLeft <= 10 ? (
                <span className={classes.skipCountdownWarn}>
                  <AccessTimeIcon className={classes.skipCountdownWarnIcon} />{' '}
                  {result.daysLeft} {result.daysLeft > 1 ? 'days' : 'day'}
                </span>
              ) : (
                <span>{result.daysLeft} days</span>
              )}
            </strong>
            <span>left to skip</span>
          </Typography>
        </div>
      )}
    </Page>
  );
}

function mapStateToProps(state: any) {
  const selector = formValueSelector('pageForm');
  const email = selector(state, 'email');
  return {
    ...state.common,
    ...state.commonForm.dataModel,
    isValidEmail: validEmail(email),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      push,
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StylePage = withStyles(styles)(UsernameUpdate);

export const UsernameUpdatePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StylePage);
