import { Action, Reducer } from 'redux';
import { AppThunkActionType } from './index';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type AlertStateType = {
  isWarning?: boolean;
  showReload?: boolean;
  message?: string;
  open?: boolean;
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

type SetAlertMessageActionType = {
  isWarning?: boolean;
  showReload?: boolean;
  message: string;
  type: 'SET_ALERT_MESSAGE';
};
type ClearAlertMessageActionType = { type: 'CLEAR_ALERT_MESSAGE' };

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownActionType = SetAlertMessageActionType | ClearAlertMessageActionType;

export const actionCreators = {
  setAlertMessage: (
    message: string,
    isWarning?: boolean,
    showReload?: boolean
  ): AppThunkActionType<KnownActionType> => (dispatch) => {
    dispatch({ type: 'SET_ALERT_MESSAGE', message, isWarning, showReload });
  },
  clearAlertMessage: (): AppThunkActionType<KnownActionType> => (dispatch) => {
    dispatch({ type: 'CLEAR_ALERT_MESSAGE' });
  },
};

export const reducer: Reducer<AlertStateType> = (
  state: AlertStateType | undefined,
  incomingAction: Action
): AlertStateType => {
  const action = incomingAction as KnownActionType;
  switch (action.type) {
    case 'SET_ALERT_MESSAGE':
      return {
        ...state,
        message: action.message,
        isWarning: action.isWarning,
        showReload: action.showReload,
        open: true,
      };
    case 'CLEAR_ALERT_MESSAGE':
      return {
        ...state,
        message: undefined,
        open: false,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      return state || { message: '' };
  }
};
