import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  isInvalid,
  isPristine,
} from 'redux-form';
import { withStyles, StyleRules } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  oneCapitalValidator,
  oneLowercaseValidator,
  oneNumberValidator,
  minLengthValidator,
  passwordsMatchValidator,
} from '@dealersocket/ds-ui-react/PasswordValidation/password-validators';
import { PasswordValidation } from '@dealersocket/ds-ui-react/PasswordValidation';
import { ChevronLeftCircleIcon } from '../../icons/chevron-left-circle-icon';

const styles = (theme: Theme): StyleRules => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      height: 80,
    },
  },
  container: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  form: {
    flex: 'auto',
    minWidth: 225,
    [theme.breakpoints.up('sm')]: {
      marginRight: 24,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: 15,
    },
  },
  validationContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 250,
    },
  },
});

type InternalProps = {
  classes: JssClasses;
  confirmNewPasswordValue: string;
  hideReturnButton: boolean;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isCurrentPasswordPopulated: boolean;
  isNewPasswordPopulated: boolean;
  isSubmitting: boolean;
  invalid: boolean;
  newPasswordValue: string;
  pristine: boolean;
};

export type ExternalProps = {
  busy?: boolean;
  requireCurrentPassword: boolean;
  onCancel: any;
};

type PasswordFormProps = InternalProps & ExternalProps;

function PasswordFormCore(
  props: PasswordFormProps
): React.ReactElement<PasswordFormProps> {
  const {
    busy,
    classes,
    confirmNewPasswordValue,
    handleSubmit,
    hideReturnButton,
    isCurrentPasswordPopulated,
    isNewPasswordPopulated,
    isSubmitting,
    invalid,
    newPasswordValue,
    pristine,
    requireCurrentPassword,
    onCancel,
  } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <FormGroup>
          <FormTextField
            autoComplete="username"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            data-e2e="usernameField"
            disabled
            name="username"
            type="text"
            label="Username"
          />
          {requireCurrentPassword ? (
            <FormTextField
              autoComplete="current-password"
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                style: {
                  fontSize: 16,
                },
              }}
              data-e2e="currentPasswordField"
              disabled={isSubmitting}
              name="currentPassword"
              type="password"
              label="Current password"
              autoFocus
              required
            />
          ) : null}
          <FormTextField
            autoComplete="new-password"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            data-e2e="newPasswordField"
            disabled={isSubmitting}
            name="newPassword"
            type={values.showPassword ? 'text' : 'password'}
            label="New password"
            autoFocus={!requireCurrentPassword}
            required
            validate={[
              oneCapitalValidator,
              oneLowercaseValidator,
              oneNumberValidator,
              minLengthValidator,
            ]}
            endAdornment={
              <IconButton
                data-e2e="visibilityButton"
                onClick={handleClickShowPassword}
                tabIndex={-1}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
          <FormTextField
            autoComplete="new-password"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            data-e2e="confirmNewPasswordField"
            disabled={isSubmitting}
            name="confirmNewPassword"
            type={values.showPassword ? 'text' : 'password'}
            label="Repeat new password"
            required
            validate={[passwordsMatchValidator]}
            endAdornment={
              <IconButton
                data-e2e="visibilityButton"
                onClick={handleClickShowPassword}
                tabIndex={-1}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            }
          />
        </FormGroup>
        <div className={classes.buttonContainer}>
          {!hideReturnButton && (
            <Button onClick={onCancel} data-e2e="cancelButton">
              <ChevronLeftCircleIcon />
              Return To Login
            </Button>
          )}
          <Button
            disabled={
              (requireCurrentPassword && !isCurrentPasswordPopulated) ||
              !isNewPasswordPopulated ||
              invalid ||
              pristine ||
              isSubmitting ||
              busy
            }
            data-e2e="setPasswordButton"
            type="submit"
            color="primary"
          >
            Set Password
          </Button>
        </div>
      </form>
      <div className={classes.validationContainer}>
        <PasswordValidation
          newPassword={newPasswordValue}
          confirmNewPassword={confirmNewPasswordValue}
        />
      </div>
    </div>
  );
}

const StyledPasswordForm = withStyles(styles)(PasswordFormCore as any);

const PasswordForm = reduxForm({
  form: 'passwordForm',
})(StyledPasswordForm as any);

const selector = formValueSelector('passwordForm');

function mapStateToProps(state: any) {
  const newPasswordValue = selector(state, 'newPassword');
  const confirmNewPasswordValue = selector(state, 'confirmNewPassword');

  return {
    ...state.commonForm,
    isCurrentPasswordPopulated: selector(state, 'currentPassword'),
    isNewPasswordPopulated: newPasswordValue && confirmNewPasswordValue,
    invalid: isInvalid('passwordForm'),
    pristine: isPristine('passwordForm'),
    newPasswordValue,
    confirmNewPasswordValue,
  };
}

export const ConnectedPasswordForm: any = connect(mapStateToProps)(
  PasswordForm
);
