import React from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import PersonIcon from '@material-ui/icons/Person';
import FormGroup from '@material-ui/core/FormGroup';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import { ChevronRightCircleIcon } from '../../shared/icons/chevron-right-circle-icon';
import {
  required,
  validEmail,
  validEmailCustom,
} from '../../shared/utils/validation';
import { useAxios } from '../../shared/utils/use-axios';
import { axiosHelper } from '../../shared/utils/axios-helper';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';

const styles = (theme: Theme): StyleRules => {
  return {
    buttonStyle: {
      whiteSpace: 'nowrap',
      marginLeft: 12,
      padding: '10px 40px',
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    formGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    buttonGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    hidden: {
      display: 'none',
    },
    textFieldIcon: { marginRight: 5 },
  };
};

export type EmailVerificationProps = {
  email: string;
  isSubmitting: boolean;
  replace: any;
  isValidEmail: boolean;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

type InternalProps = {
  classes: JssClasses;
};

const EmailVerification = (props: EmailVerificationProps & InternalProps) => {
  const { isSubmitting, isValidEmail, webPSupported, classes } = props;

  const [result, busy] = useAxios('/email/GetVerification', undefined);

  const skipEmailVerification = () => {
    const body = {};

    axiosHelper(
      '/email/skipEmailVerification',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/email/sendVerification',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  return (
    <Page>
      <Typography
        data-e2e="emailVerificationTitle"
        variant={TypographyVariants.H5}
        paragraph
      >
        EMAIL VERIFICATION REQUIRED
      </Typography>
      <Typography
        data-e2e="emailVerificationBody"
        variant={TypographyVariants.Body1}
        paragraph
      >
        Please confirm or enter your email address then click <b>Send Email</b>.
        We will email your verification code to type on the next screen.
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: result.email }}
        enableReinitialize
      >
        <FormGroup className={classes.formGroupStyle}>
          <FormTextField
            autoComplete="email"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="email"
            startAdornment={<PersonIcon className={classes.textFieldIcon} />}
            autoFocus
            required
            placeholder="Email"
            disabled={isSubmitting || busy}
            fullWidth={webPSupported}
            validate={[
              required,
              validEmailCustom('Please enter a valid email address'),
            ]}
          />
          <Button
            data-e2e="submitButton"
            disabled={isSubmitting || busy || !isValidEmail}
            type="submit"
            color="primary"
            className={classes.buttonStyle}
          >
            Send Email
          </Button>
        </FormGroup>
        <FormGroup className={classes.buttonGroupStyle}>
          <Button
            data-e2e="returnToLoginButton"
            disabled={isSubmitting}
            onClick={cancel}
            className={classes.secondaryButtonStyle}
          >
            <ChevronLeftCircleIcon />
            Return To Login
          </Button>

          {result.allowSkip ? (
            <Button
              data-e2e="skipButton"
              disabled={isSubmitting}
              onClick={skipEmailVerification}
              className={classes.secondaryButtonStyle}
            >
              Skip For Now
              <ChevronRightCircleIcon />
            </Button>
          ) : null}
        </FormGroup>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const email = selector(state, 'email');
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isValidEmail: validEmail(email),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StylePage = withStyles(styles)(EmailVerification);

export const EmailVerificationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StylePage);
