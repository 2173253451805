import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { theme } from '@dealersocket/ds-ui-react/theme';

const useStyles = makeStyles(() => ({
  cls1: () => ({
    fill: '#4D4E4E',
  }),
  cls2: () => ({
    fill: '#01508F',
    fillRule: 'evenodd',
  }),
  cls3: {
    fill: theme.palette.common.white,
  },
}));

export const CrmStackedLogoIcon = () => {
  const classes = useStyles();
  return (
    <svg version="1.1" id="Layer_1" viewBox="0 0 432 117.2" height="50px">
      <g>
        <g>
          <g>
            <path
              className={classes.cls1}
              d="M145.9,84.9c-1.5-0.9-3.1-1.3-4.9-1.3c-1.4,0-2.8,0.4-4,1.1c-1,0.6-1.8,1.3-2.4,2V75.7h-4.3v28.5h4.3V102
					c0.7,0.7,1.5,1.3,2.5,1.9c1.2,0.6,2.5,1,4,1c1.8,0,3.4-0.5,4.8-1.4c1.4-0.9,2.6-2.2,3.4-3.8c0.8-1.6,1.3-3.5,1.3-5.5
					c0-2.1-0.4-3.9-1.3-5.5C148.5,87,147.3,85.8,145.9,84.9z M145.7,97.6c-0.5,1-1.2,1.8-2.1,2.4c-0.9,0.6-2,0.9-3.1,0.9
					c-1.2,0-2.2-0.3-3.1-0.9c-0.9-0.6-1.6-1.4-2.2-2.4c-0.5-1-0.8-2.2-0.8-3.4c0-1.3,0.3-2.4,0.8-3.4c0.5-1,1.2-1.8,2.1-2.4
					c0.9-0.6,2-0.9,3.2-0.9c1.2,0,2.2,0.3,3.1,0.9c0.9,0.6,1.6,1.4,2.1,2.4c0.5,1,0.8,2.2,0.8,3.4C146.4,95.4,146.2,96.6,145.7,97.6
					z"
            />
            <path
              className={classes.cls1}
              d="M162.9,98.6c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.3-0.1,0.4c0-0.1-0.1-0.2-0.1-0.4
					c-0.1-0.2-0.2-0.4-0.2-0.6l-6.3-14.8l0-0.1h-5l9.5,21.3l-3.5,8h4.6l3.5-8.9l8.4-20.1l0.1-0.2h-5.1L162.9,98.6z"
            />
            <path
              className={classes.cls1}
              d="M207.3,81.3c-1.1-1.2-2.4-2.2-4-3c-1.6-0.7-3.5-1.1-5.6-1.1H187v27.1h10.8c2.8,0,5.2-0.6,7.1-1.8
					c1.9-1.2,3.4-2.9,4.3-4.9c1-2,1.4-4.3,1.4-6.8c0-1.8-0.3-3.6-0.8-5.2C209.2,84,208.4,82.5,207.3,81.3z M205.2,95.5
					c-0.6,1.4-1.5,2.5-2.7,3.4c-1.2,0.8-2.9,1.3-4.9,1.3h-6.1V81.6h6.1c2,0,3.6,0.4,4.9,1.3c1.2,0.8,2.2,2,2.7,3.4
					c0.6,1.4,0.9,3,0.9,4.6C206,92.5,205.7,94.1,205.2,95.5z"
            />
            <path
              className={classes.cls1}
              d="M228.2,84.8c-1.4-0.8-3-1.3-4.7-1.3c-1.9,0-3.6,0.4-5.2,1.3c-1.6,0.9-2.9,2.1-3.9,3.7
					c-1,1.6-1.5,3.6-1.5,5.8c0,2,0.5,3.8,1.4,5.3c0.9,1.6,2.2,2.8,3.8,3.7c1.6,0.9,3.5,1.3,5.7,1.3c1.6,0,3.1-0.3,4.6-0.9
					c1.4-0.6,2.6-1.3,3.4-2.1l0.2-0.2l-2.1-3.1l-0.1,0.1c-0.6,0.5-1.3,1-2.1,1.5c-0.9,0.5-2,0.8-3.4,0.8c-1.9,0-3.5-0.5-4.8-1.6
					c-1.2-1-1.9-2.4-2.2-4h15.5l0-1.8c0-2-0.4-3.7-1.3-5.2C230.7,86.8,229.6,85.6,228.2,84.8z M228.4,91.8h-11
					c0.4-1.6,1.1-2.7,2.1-3.3c1.1-0.7,2.4-1,3.9-1c0.8,0,1.6,0.2,2.3,0.5c0.7,0.4,1.3,0.9,1.8,1.5c0.5,0.6,0.8,1.4,0.8,2.2V91.8z"
            />
            <path
              className={classes.cls1}
              d="M251.3,86.4c-0.6-0.7-1.4-1.3-2.3-1.9c-1.1-0.6-2.4-0.9-3.8-0.9c-1.9,0-3.7,0.5-5.2,1.3
					c-1.5,0.9-2.7,2.1-3.6,3.7c-0.9,1.6-1.3,3.4-1.3,5.5c0,2.1,0.4,3.9,1.3,5.5c0.8,1.6,2,2.9,3.4,3.8c1.4,0.9,3,1.4,4.7,1.4
					c1.6,0,3.1-0.4,4.3-1.1c1.1-0.6,1.9-1.3,2.5-2.1v2.8h4.3V84h-4.3V86.4z M250.7,97.6c-0.5,1-1.2,1.8-2.1,2.4
					c-0.9,0.6-2,0.9-3.2,0.9c-1.2,0-2.2-0.3-3.1-0.9c-0.9-0.6-1.6-1.4-2.1-2.4c-0.5-1-0.8-2.2-0.8-3.4c0-1.3,0.3-2.4,0.8-3.4
					c0.5-1,1.2-1.8,2.1-2.4c0.9-0.6,1.9-0.9,3.1-0.9c1.2,0,2.3,0.3,3.2,0.9c0.9,0.6,1.6,1.4,2.1,2.4c0.5,1,0.8,2.1,0.8,3.4
					C251.5,95.4,251.2,96.6,250.7,97.6z"
            />
            <rect
              x="260.6"
              y="75.7"
              className={classes.cls1}
              width="4.3"
              height="28.7"
            />
            <path
              className={classes.cls1}
              d="M302.1,83.6c-1.3,0-2.5,0.4-3.7,1.2c-1,0.7-1.8,1.5-2.3,2.5V84h-4.3v20.4h4.3V93.1c0-1,0.3-1.9,0.8-2.7
					c0.5-0.8,1.2-1.3,1.9-1.7c0.8-0.4,1.6-0.6,2.4-0.6c0.4,0,0.8,0,1.1,0.1c0.4,0.1,0.7,0.2,0.9,0.3l0.2,0.1l1.1-4.6l-0.1-0.1
					C303.8,83.7,303.1,83.6,302.1,83.6z"
            />
            <path
              className={classes.cls1}
              d="M321.8,90c-1.4-0.6-2.8-1.1-4.4-1.4c-1.7-0.4-3-0.8-4-1.4c-1-0.6-1.5-1.5-1.5-2.7c0-1.1,0.5-1.9,1.4-2.6
					c0.9-0.6,2.1-1,3.5-1c1.4,0,2.6,0.4,3.6,1c1,0.7,1.9,1.6,2.6,2.7l0.1,0.2l3.2-3.1l-0.1-0.1c-0.9-1.5-2.1-2.7-3.7-3.5
					c-1.6-0.8-3.5-1.2-5.6-1.2c-1.7,0-3.2,0.3-4.7,0.9c-1.4,0.6-2.6,1.5-3.5,2.7c-0.9,1.1-1.3,2.5-1.3,4.1c0,2.4,0.8,4.3,2.3,5.6
					c1.5,1.3,3.6,2.2,6.1,2.7c2,0.4,3.5,0.9,4.6,1.6c1.1,0.7,1.6,1.6,1.6,2.9c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,0.9-1.9,1.2
					c-0.8,0.3-1.6,0.4-2.5,0.4c-1.8,0-3.3-0.4-4.4-1.2c-1.1-0.8-2.2-2-3.3-3.5l-0.1-0.2l-3,3.4l-0.1,0.1l0.1,0.1
					c1.3,1.9,2.8,3.2,4.6,4c1.7,0.8,3.7,1.2,5.8,1.2c3.1,0,5.6-0.8,7.3-2.3c1.8-1.5,2.7-3.6,2.7-6.2c0-1.5-0.4-2.7-1.3-3.8
					C324.3,91.5,323.1,90.6,321.8,90z"
            />
            <path
              className={classes.cls1}
              d="M344.9,84.9c-1.6-0.9-3.4-1.4-5.3-1.4c-2,0-3.7,0.5-5.3,1.4c-1.6,0.9-2.9,2.2-3.8,3.8
					c-0.9,1.6-1.4,3.4-1.4,5.5c0,2.1,0.5,3.9,1.4,5.5c0.9,1.6,2.2,2.9,3.8,3.8c1.6,0.9,3.4,1.4,5.3,1.4c2,0,3.7-0.5,5.3-1.4
					c1.6-0.9,2.8-2.2,3.8-3.8c0.9-1.6,1.4-3.4,1.4-5.5c0-2.1-0.5-3.9-1.4-5.5C347.8,87.1,346.5,85.8,344.9,84.9z M345.7,94.2
					c0,1.3-0.2,2.5-0.8,3.5c-0.5,1-1.3,1.8-2.2,2.4c-0.9,0.6-2,0.9-3.1,0.9c-1.2,0-2.2-0.3-3.2-0.9c-0.9-0.6-1.7-1.4-2.2-2.4
					c-0.5-1-0.8-2.2-0.8-3.5c0-1.3,0.2-2.5,0.8-3.5c0.5-1,1.3-1.8,2.2-2.4s2-0.8,3.2-0.8c1.2,0,2.2,0.3,3.1,0.8
					c0.9,0.6,1.7,1.3,2.2,2.4C345.5,91.7,345.7,92.8,345.7,94.2L345.7,94.2z"
            />
            <path
              className={classes.cls1}
              d="M368.5,97.9c-0.6,0.7-1.4,1.4-2.3,2c-0.9,0.6-2,1-3.3,1c-1.1,0-2.1-0.3-3.1-0.9c-1-0.6-1.8-1.4-2.3-2.4
					c-0.6-1-0.9-2.2-0.9-3.5c0-1.3,0.3-2.5,0.8-3.5c0.6-1,1.3-1.8,2.3-2.4c1-0.6,2-0.9,3.2-0.9c1,0,2.1,0.3,3.1,0.9
					c1.1,0.6,1.9,1.3,2.5,2.2l0.1,0.2l2.4-2.9l-0.1-0.1c-0.7-1.2-1.9-2.2-3.4-3s-3.2-1.1-5.1-1.1c-1.9,0-3.6,0.5-5.1,1.4
					c-1.5,0.9-2.7,2.2-3.6,3.8c-0.9,1.6-1.3,3.4-1.3,5.4c0,2,0.4,3.8,1.3,5.4c0.9,1.6,2.1,2.9,3.6,3.8c1.5,0.9,3.2,1.4,5.1,1.4
					c1.2,0,2.4-0.2,3.5-0.6c1.1-0.4,2.1-0.9,2.9-1.5c0.9-0.6,1.5-1.3,2-2l0.1-0.1l-2.4-2.9L368.5,97.9z"
            />
            <polygon
              className={classes.cls1}
              points="392.8,84.2 393.1,84 387.3,84 378.3,92.8 378.3,75.7 374,75.7 374,104.4 378.3,104.4 378.3,97.7
					380.4,95.8 387.8,104.3 387.8,104.4 393.3,104.4 383.4,93 				"
            />
            <polygon
              className={classes.cls1}
              points="423.5,84 423.5,78.8 419.2,78.8 419.2,84 415,84 415,88.1 419.2,88.1 419.2,104.4 423.5,104.4
					423.5,88.1 428.2,88.1 428.2,84 				"
            />
            <path
              className={classes.cls1}
              d="M283.5,84.8c-1.4-0.8-3-1.3-4.7-1.3c-1.9,0-3.6,0.4-5.2,1.3c-1.6,0.9-2.9,2.1-3.9,3.7
					c-1,1.6-1.5,3.6-1.5,5.8c0,2,0.5,3.8,1.4,5.3c0.9,1.6,2.2,2.8,3.8,3.7c1.6,0.9,3.5,1.3,5.7,1.3c1.6,0,3.1-0.3,4.6-0.9
					c1.4-0.6,2.6-1.3,3.4-2.1l0.2-0.2l-2.1-3.1l-0.1,0.1c-0.6,0.5-1.3,1-2.1,1.5c-0.9,0.5-2,0.8-3.4,0.8c-1.9,0-3.5-0.5-4.8-1.6
					c-1.2-1-1.9-2.4-2.2-4h15.5l0-1.8c0-2-0.4-3.7-1.3-5.2C286,86.8,284.9,85.6,283.5,84.8z M283.7,91.8h-11
					c0.4-1.6,1.1-2.7,2.1-3.3c1.1-0.7,2.4-1,3.9-1c0.8,0,1.6,0.2,2.3,0.5c0.7,0.4,1.3,0.9,1.8,1.5c0.5,0.6,0.8,1.4,0.8,2.2V91.8z"
            />
            <path
              className={classes.cls1}
              d="M408.4,84.8c-1.4-0.8-3-1.3-4.7-1.3c-1.9,0-3.6,0.4-5.2,1.3c-1.6,0.9-2.9,2.1-3.9,3.7
					c-1,1.6-1.5,3.6-1.5,5.8c0,2,0.5,3.8,1.4,5.3c0.9,1.6,2.2,2.8,3.8,3.7c1.6,0.9,3.5,1.3,5.7,1.3c1.6,0,3.1-0.3,4.6-0.9
					c1.4-0.6,2.6-1.3,3.4-2.1l0.2-0.2l-2.1-3.1l-0.1,0.1c-0.6,0.5-1.3,1-2.1,1.5c-0.9,0.5-2,0.8-3.4,0.8c-1.9,0-3.5-0.5-4.8-1.6
					c-1.2-1-1.9-2.4-2.2-4H413l0-1.8c0-2-0.4-3.7-1.3-5.2C411,86.8,409.8,85.6,408.4,84.8z M408.7,91.8h-11c0.4-1.6,1.1-2.7,2.1-3.3
					c1.1-0.7,2.4-1,3.9-1c0.8,0,1.6,0.2,2.3,0.5c0.7,0.4,1.3,0.9,1.8,1.5c0.5,0.6,0.8,1.4,0.8,2.2V91.8z"
            />
          </g>
          <g>
            <path
              className={classes.cls2}
              d="M94.5,19.3c-20.7-20.7-54.2-20.7-74.9,0C-1.1,40-1.1,73.5,19.6,94.2c20.7,20.7,54.2,20.7,74.9,0
					C115.2,73.5,115.2,40,94.5,19.3z"
            />
            <path
              className={classes.cls3}
              d="M46.3,59.1c0,6.7,8.5,12.2,19,12.2h22.4c1.8-3.7,2.9-7.9,3.2-12.2H46.3z"
            />
            <path
              className={classes.cls3}
              d="M69.9,88.2c5.1-2.1,9.5-5.3,13-9.4H63.4C63.4,82.5,66.3,85.9,69.9,88.2z"
            />
            <path
              className={classes.cls3}
              d="M48.2,52.2h42.4c-0.6-4.4-2-8.5-4.1-12.2H29.3C29.3,46.7,37.8,52.2,48.2,52.2z"
            />
          </g>
          <g>
            <path
              className={classes.cls1}
              d="M146.1,18.9c2.3-1.3,4.8-2,7.5-2c2.3,0,4.5,0.6,6.4,1.6c2,1.1,3.8,2.5,5.4,4.2l0.2,0.2l5.2-5.4l-0.1-0.2
					c-1-1.4-2.3-2.8-4-4c-1.7-1.2-3.7-2.3-5.9-3.1c-2.2-0.8-4.7-1.2-7.2-1.2c-3.1,0-6.1,0.6-8.9,1.8c-2.8,1.2-5.3,2.9-7.5,5.1
					c-2.2,2.2-3.9,4.7-5.1,7.7c-1.2,2.9-1.8,6.2-1.8,9.6c0,4.9,1,9.2,3,12.9c2,3.7,4.7,6.5,8.2,8.5c3.4,2,7.3,3,11.5,3
					c2.5,0,4.8-0.3,7-1c2.2-0.6,4.1-1.5,5.8-2.4c1.7-1,3-2.1,4-3.1l0.1-0.2l-3.9-6.4l-0.3,0.3c-1.2,1.2-2.8,2.4-4.7,3.4
					c-2,1-4.3,1.5-6.8,1.5c-2.8,0-5.5-0.6-7.9-1.8c-2.4-1.2-4.4-3-5.8-5.5c-1.5-2.4-2.2-5.5-2.2-9.2c0-3.4,0.7-6.3,2.2-8.7
					C141.8,22.1,143.7,20.2,146.1,18.9z"
            />
            <path
              className={classes.cls1}
              d="M210.2,32.7c1.7-2.5,2.6-5.2,2.6-8c0-3.1-0.7-5.9-2.2-8.1c-1.5-2.3-3.4-4-5.7-5.3c-2.3-1.2-4.7-1.8-7.2-1.8
					h-20.3V57h7.9V39.4h10.2l9.8,17.5l0.1,0.2l9.5,0.1l-11-19C206.5,36.9,208.6,35.1,210.2,32.7z M204.8,24.7c0,1.8-0.6,3.5-1.7,5
					c-1.1,1.5-2.7,2.2-4.8,2.2h-13.1V17.1h11.2c2.3,0,4.3,0.7,5.9,2C204,20.4,204.8,22.3,204.8,24.7z"
            />
            <path
              className={classes.cls1}
              d="M245.1,34.2L229.9,9.5h-7.6V57h7.9V37.5c0-2.8,0-5.5-0.1-8c-0.1-2.1-0.2-4.2-0.4-6.2l12.8,20.2l0.1,0.1h4.8
					l13.2-20.4c-0.2,2.1-0.3,4.2-0.4,6.3c-0.1,2.5-0.2,5.2-0.2,8V57h7.8V9.5h-7.2L245.1,34.2z"
            />
            <polygon
              className={classes.cls1}
              points="286.8,4.9 284.6,8.5 282.4,4.9 281.1,4.9 281.1,11.4 282.6,11.4 282.6,7.7 284,10 285.2,10
					286.6,7.5 286.6,11.4 288.1,11.4 288.1,4.9 				"
            />
            <polygon
              className={classes.cls1}
              points="275.2,6.2 277,6.2 277,11.4 278.3,11.4 278.3,6.2 280.2,6.2 280.2,4.9 275.2,4.9 				"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
