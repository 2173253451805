import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import Card from '@material-ui/core/Card';
import { withStyles, StyleRules } from '@material-ui/styles';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { CheckboxField } from '@dealersocket/ds-ui-react/fields/CheckboxField';
import { DsLogoProgress } from '@dealersocket/ds-ui-react/DsLogoProgress';
import {
  AutoMateLongIcon,
  InvPlusLogoIcon,
  CrmLongIcon,
  DealerFireLongIcon,
  IdmsLongIcon,
  PrecisePriceLongIcon,
  RevenueRadarLongIcon,
} from '@dealersocket/ds-ui-react/logos';
import { getProductFromEnum } from '../../../shared/enums';
import { axiosHelper } from '../../../shared/utils/axios-helper';
import { continueLogin } from '../../../shared/utils/continue-login';
import { Page } from '../../../shared/components/Page';
import { useAxios } from '../../../shared/utils/use-axios';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';
import * as CommonStore from '../../../store/Common';

const styles = (): StyleRules => {
  return {
    bottomContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: 572,
    },
    description: {
      marginBottom: 30,
    },
    headerTitle: {
      marginBottom: 15,
    },
    leftContent: {
      backgroundColor: '#f5f5f5',
      paddingBottom: 40,
      paddingTop: 40,
      minHeight: 572,
      width: 283,
    },
    loader: { margin: 'auto' },
    loaderContainer: { display: 'flex', height: '100%' },
    menuItem: {
      backgroundColor: 'transparent',
      height: 76,
      width: '100%',
      paddingLeft: 40,
      paddingRight: 40,
    },
    rightContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '40px',
      width: '60%',
    },
  };
};

type InternalProps = {
  classes: any;
  push?: any;
  replace?: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const LinkingIntroComponent = (props: InternalProps) => {
  const { classes } = props;

  const title = 'Set up your DealerSocket Account';
  const header1 = 'One username. One password.';
  const header2 = 'All your DealerSocket products.';
  const description =
    'Follow this two-step process to connect all your accounts under your master login. It only takes a moment.';

  const iconProps = { width: '210px', height: '36px', viewBox: '0 0 210 36' };

  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const [result, busy] = useAxios(
    '/accountSetup/getEnabledLinkingProducts',
    undefined
  );

  const products = result && Array.isArray(result) ? [...result] : [];
  if (products.find((p) => p.type === 1)) {
    products.push({
      type: -1,
    });
  }

  if (products.find((p) => p.type === 3)) {
    products.push({
      type: -2,
    });
  }

  products.sort((p1, p2) => {
    if (getProductFromEnum(p1.type) > getProductFromEnum(p2.type)) return 1;
    if (getProductFromEnum(p1.type) < getProductFromEnum(p2.type)) return -1;
    return 0;
  });

  const onSkip = () => {
    axiosHelper(
      `/accountSetup/skipLinking?doNotAskAgain=${doNotAskAgain}`,
      {
        method: 'post',
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload) {
          if (payload.isSpaRoute) {
            props.replace(payload.redirectUrl);
          } else {
            window.location.replace(payload.redirectUrl);
          }
        } else {
          continueLogin(
            props.setIsSubmitting,
            props.replace,
            props.setAlertMessage,
            props.clearAlertMessage
          );
        }
      })
      .catch(() => {});
  };

  const getItem = (product: number) => {
    switch (product) {
      case 1:
        return <CrmLongIcon {...iconProps} />;
      case 2:
        return <InvPlusLogoIcon {...iconProps} />;
      case 3:
        return <DealerFireLongIcon {...iconProps} />;
      case 4:
        return <AutoMateLongIcon {...iconProps} />;
      case 5:
        return <IdmsLongIcon {...iconProps} />;

      case -1:
        return <RevenueRadarLongIcon {...iconProps} />;
      case -2:
        return <PrecisePriceLongIcon {...iconProps} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Page pageTitle={title} removePadding paperSize="lg">
        <div className={classes.root}>
          <Card>
            <div className={classes.contentContainer}>
              <div className={classes.leftContent}>
                {busy ? (
                  <div className={classes.loaderContainer}>
                    <div className={classes.loader}>
                      <DsLogoProgress />
                    </div>
                  </div>
                ) : (
                  <div className={classes.buttonContainer}>
                    {products.map((p) => (
                      <div
                        className={classes.menuItem}
                        key={`${p.type}`}
                        data-e2e={`${p.type}-menu-button`}
                      >
                        {getItem(p.type)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={classes.rightContent}>
                <div>
                  <div className={classes.headerTitle}>
                    <Typography variant={TypographyVariants.H5}>
                      {header1}
                    </Typography>
                    <Typography variant={TypographyVariants.H5}>
                      {header2}
                    </Typography>
                  </div>
                  <div className={classes.description}>
                    <Typography>{description}</Typography>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      data-e2e="start-now"
                      onClick={() => props.push('/accountSetup/linking')}
                    >
                      Start Now
                    </Button>
                  </div>
                </div>
                <div className={classes.bottomContainer}>
                  <CheckboxField
                    label="Do not ask again"
                    data-e2e="dna-checkbox"
                    checked={doNotAskAgain}
                    onChange={(event, checked) => setDoNotAskAgain(checked)}
                  />
                  <Button data-e2e="not-now" onClick={onSkip}>
                    Not Now
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Page>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      push,
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StyledPage = withStyles(styles)(LinkingIntroComponent);

export const LinkingIntroPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledPage);
