import React from 'react';
import { Route } from 'react-router';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useAxios } from '../../utils/use-axios';

type PartialLoginRouteProps = {
  path: string;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

export const PartialLoginRoute = (props: PartialLoginRouteProps) => {
  const { component, ...other } = props;

  const [result, busy] = useAxios('/account/partialLoginValid', undefined);

  return busy ? null : (
    <Route
      {...other}
      render={(routeProps) =>
        result ? (
          renderMergedProps(component, routeProps, other)
        ) : (
          <Redirect
            to={{
              pathname: '/loginExpired',
            }}
          />
        )
      }
    />
  );
};
