import { replace } from 'connected-react-router';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { axiosApi } from '@dealersocket/react-common';
import { useAsyncOperation } from '../../shared/utils/use-async-operation';
import * as AlertStore from '../../store/Alert';
import * as CommonStore from '../../store/CommonForm';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { LandingPageUrl } from '../../shared/constants';
import { VerifyPage } from '../../shared/components/Verify';

type VerifyPasswordResetProps = {
  isSubmitting: boolean;
  replace: any;
  verificationCode: string;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const VerifyPasswordReset = (props: VerifyPasswordResetProps) => {
  const { isSubmitting, verificationCode } = props;

  const [disableResend, setDisableResend] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [submittedCode, setSubmittedCode] = useState('');
  const [timeoutEnd, setTimeoutEnd] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const [username] = useState(decodeURIComponent(params.get('username') || ''));
  const [returnUrl] = useState(params.get('returnUrl') || '');
  const [lastSent, setLastSent] = useState();
  async function resendVerificationCode() {
    return axiosApi('/password/resendReset', {
      method: 'post',
      data: { username, returnUrl },
    });
  }

  const [
    resendVerificationCodeFunc,
    busy,
  ] = useAsyncOperation(resendVerificationCode, (result, error) =>
    error ? props.setAlertMessage(error) : startTimer(result)
  );

  const startTimer = (result: any) => {
    let lastSendTime = lastSent;
    if (!lastSendTime) {
      lastSendTime = Date.now();
      setLastSent(lastSendTime);
    }
    const lastSendIso = new Date(lastSendTime).toISOString();
    lastSendTime = Date.parse(lastSendIso) + result.delay;
    const currentTime = Date.now();

    if (lastSendTime - currentTime > 0) {
      setTimeoutEnd(lastSendTime);
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
        setLastSent(null);
      }, lastSendTime - currentTime);
    }
  };

  const onSubmit = (values) => {
    const body = {
      ...values,
      username,
      returnUrl,
    };

    axiosHelper(
      '/password/verify',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {
        setSubmittedCode(verificationCode);
        setIsInvalid(true);
      });
  };

  const cancel = () => {
    window.location.replace(returnUrl || LandingPageUrl);
  };

  return (
    <VerifyPage
      cancel={cancel}
      disableResend={disableResend}
      isInvalid={isInvalid}
      isSubmitting={isSubmitting}
      loading={busy}
      onSubmit={onSubmit}
      resendCode={resendVerificationCodeFunc}
      submittedCode={submittedCode}
      timeoutEnd={timeoutEnd}
      verificationCode={verificationCode}
    />
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const verificationCode = selector(state, 'verificationCode');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    verificationCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const VerifyPasswordResetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyPasswordReset);
