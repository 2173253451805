import { StyleRules, Theme } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@dealersocket/ds-ui-react/Button';
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from '@material-ui/styles';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import { StyledEulaContent } from './eula-content';

type PrintableEulaContentProps = {
  classes: JssClasses;
  header: any;
  mainEulaContent: any;
};

const printableEulaContentStyle = (theme: Theme): StyleRules => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: { fontSize: 20, cursor: 'pointer', color: '#acacac', marginRight: 6 },
    mobileIcon: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        cursor: 'pointer',
        color: '#acacac',
        marginRight: 6,
        display: 'inline-block',
      },
    },
    buttonContainer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  };
};

const PrintableEulaContentCore = (props: PrintableEulaContentProps) => {
  const { classes, header, mainEulaContent } = props;
  const isMountedRef: any = useRef(undefined);
  return (
    <div>
      <ReactToPrint
        pageStyle=""
        trigger={() => (
          <div className={classes.container}>
            <div className={classes.buttonContainer}>
              <Button data-e2e="btn-icon">
                <PrintIcon className={classes.icon} />
                Print
              </Button>
            </div>
            <PrintIcon className={classes.mobileIcon} />
          </div>
        )}
        content={() => isMountedRef.current}
      />
      <div style={{ display: 'none' }}>
        <StyledEulaContent
          ref={isMountedRef}
          header={header}
          mainEulaContent={mainEulaContent}
        />
      </div>
    </div>
  );
};

export const PrintableEulaContent = withStyles(printableEulaContentStyle)(
  PrintableEulaContentCore
);
