import { axiosApi } from '@dealersocket/react-common';

export function axiosHelper(
  url: string,
  options: any,
  setSubmitting: any,
  replace: any,
  setAlertMessage: any,
  clearAlertMessage: any
): any {
  setSubmitting(true);
  clearAlertMessage();

  return axiosApi(url, options)
    .then((payload) => {
      setSubmitting(false);
      return payload;
    })
    .catch((error) => {
      setSubmitting(false);
      if (error.response.data === 'LoginExpired') {
        replace('/loginExpired');
      } else if (error.response.status === 400 && !error.response.data) {
        setAlertMessage(
          'An error occurred. Please reload the page and try again.',
          false,
          true
        );
      } else {
        let dataMessage;
        if (
          typeof error.response.data === 'object' &&
          error.response.data !== null
        ) {
          dataMessage = JSON.stringify(error.response.data);
        } else {
          dataMessage = error.response.data;
        }
        setAlertMessage(dataMessage || error.message);
      }
      return Promise.reject(error);
    });
}
