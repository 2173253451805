import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, isInvalid } from 'redux-form';
import { replace } from 'connected-react-router';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormSelectField } from '@dealersocket/ds-ui-react/form/fields/FormSelectField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { useAxios } from '../../shared/utils/use-axios';

type LinkedAccountsProps = {
  selectedDealershipId: string;
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const useStyles = makeStyles({
  continueButton: { marginBottom: 5, marginLeft: 10, paddingBottom: 8 },
  iEContinueButton: {
    marginBottom: 5,
    marginLeft: 10,
    paddingBottom: 8,
    width: '35%',
  },
  formGroup: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  loader: { margin: 'auto' },
  loaderContainer: { display: 'flex', height: '100%' },
});

const LinkedAccounts = (props: LinkedAccountsProps) => {
  const { isSubmitting, selectedDealershipId, webPSupported } = props;
  const classes = useStyles();

  const [result] = useAxios('/account/GetLinkedAccounts', undefined);

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/admin/setLinkedAccount',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  const required: boolean = !!selectedDealershipId;

  if (Array.isArray(result)) {
    result.sort((p1, p2) => {
      if (p1.name > p2.name) return 1;
      if (p1.name < p2.name) return -1;
      return 0;
    });
  }

  return (
    <Page>
      <Typography
        data-e2e="selectLinkedAccountTitle"
        paragraph
        variant={TypographyVariants.H5}
      >
        Select an account
      </Typography>
      <Typography
        data-e2e="selectLinkedAccountBody"
        paragraph
        variant={TypographyVariants.Body1}
      >
        Select the account you wish to access. Once logged in, you must log out
        to access another account.
      </Typography>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <div className={classes.formGroup}>
            <FormSelectField
              fullWidth
              label="Account"
              name="selectedDealershipId"
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="" disabled>
                - Select -
              </MenuItem>
              {Array.isArray(result) &&
                result.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
            </FormSelectField>
            <Button
              className={
                webPSupported
                  ? classes.continueButton
                  : classes.iEContinueButton
              }
              color="primary"
              data-e2e="continueButton"
              disabled={isSubmitting || !required}
              type="submit"
            >
              Continue
            </Button>
          </div>
        </FormGroup>
        <Button
          data-e2e="returnToLoginButton"
          disabled={isSubmitting}
          onClick={cancel}
        >
          Return to Login
        </Button>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const selectedDealershipId = selector(state, 'selectedDealershipId');
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isFormInvalid: isInvalid('pageForm')(state),
    selectedDealershipId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const LinkedAccountsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedAccounts);
