import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, isInvalid } from 'redux-form';
import { replace } from 'connected-react-router';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import * as AlertStore from '../../store/Alert';
import * as CommonStore from '../../store/CommonForm';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { required } from '../../shared/utils/validation';
import { continueLogin } from '../../shared/utils/continue-login';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { axiosHelper } from '../../shared/utils/axios-helper';

type UserImpersonationProps = {
  isSubmitting: boolean;
  isFormInvalid: boolean;
  username: string;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const UserImpersonation = (props: UserImpersonationProps) => {
  const { isSubmitting, isFormInvalid, username } = props;
  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/admin/impersonateUser',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const continueAsSelf = () => {
    axiosHelper(
      '/admin/skipImpersonation',
      {
        method: 'post',
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        <FormGroup style={{ marginBottom: 15 }}>
          <FormTextField
            autoComplete="off"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="username"
            disabled={isSubmitting}
            autoFocus
            label="Username or login email of user to impersonate"
            required
          />
          <Button
            data-e2e="impersonateButton"
            disabled={
              isSubmitting || isFormInvalid || required(username) !== undefined
            }
            type="submit"
            color="primary"
          >
            Impersonate User
          </Button>
        </FormGroup>
        <Button
          data-e2e="cancelButton"
          disabled={isSubmitting}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          data-e2e="continueButton"
          onClick={continueAsSelf}
          disabled={isSubmitting}
          style={{ float: 'right' }}
        >
          Continue as Self
        </Button>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const username = selector(state, 'username');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isFormInvalid: isInvalid('pageForm')(state),
    username,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const UserImpersonationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserImpersonation);
