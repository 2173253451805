import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { replace, goBack } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import FormGroup from '@material-ui/core/FormGroup';
import PersonIcon from '@material-ui/icons/Person';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import { requiredCustom } from '../../shared/utils/validation';
import { axiosHelper } from '../../shared/utils/axios-helper';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';

const styles = (): StyleRules => ({
  textFieldIcon: { marginRight: 5 },
  textButtonPair: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  formButton: {
    marginLeft: 12,
    whiteSpace: 'nowrap',
    padding: '10px 40px',
  },
});

type ForgotPasswordProps = {
  classes: any;
  goBack: any;
  replace: any;
  isSubmitting: boolean;
  username: string;
  isUsernameFieldEmpty: boolean;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { classes, isSubmitting, isUsernameFieldEmpty, webPSupported } = props;
  const [username, setUsername] = useState(props.username);
  const [returnUrl, setReturnUrl] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!username) {
      setUsername(decodeURIComponent(params.get('username') || ''));
    }
    if (!returnUrl) {
      setReturnUrl(params.get('returnUrl') || '');
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onSubmit = (values) => {
    const body = {
      ...values,
      returnUrl,
    };

    axiosHelper(
      '/password/sendReset',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  return (
    <Page>
      <Typography
        variant={TypographyVariants.H5}
        data-e2e="forgotPasswordTitle"
        paragraph
      >
        RESET YOUR PASSWORD
      </Typography>
      <Typography
        variant={TypographyVariants.Body1}
        data-e2e="forgotPasswordContent"
        paragraph
      >
        Enter your username or login email and we&apos;ll send you a password
        reset code to the email associated with your account.
      </Typography>
      <Form onSubmit={onSubmit} initialValues={{ username }} enableReinitialize>
        <FormGroup className={classes.textButtonPair}>
          <FormTextField
            autoComplete="username"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            className={classes.textField}
            disabled={isSubmitting}
            name="username"
            autoFocus
            required
            fullWidth={webPSupported}
            startAdornment={<PersonIcon className={classes.textFieldIcon} />}
            placeholder="Username"
            validate={[requiredCustom('Please enter your username')]}
          />
          <Button
            data-e2e="submitButton"
            disabled={isSubmitting || isUsernameFieldEmpty}
            type="submit"
            color="primary"
            className={classes.formButton}
          >
            Send email
          </Button>
        </FormGroup>
      </Form>
      <Button
        data-e2e="returnToLoginButton"
        disabled={isSubmitting}
        onClick={() => props.goBack()}
      >
        <ChevronLeftCircleIcon />
        RETURN TO LOGIN
      </Button>
    </Page>
  );
};

const StyledForgotPassword = withStyles(styles)(ForgotPassword);

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const username = selector(state, 'username');
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isUsernameFieldEmpty:
      username === null || username === undefined || username.trim() === '',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      goBack,
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const ForgotPasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledForgotPassword);
