import React from 'react';
import { LandingPageUrl } from '../../shared/constants';
import { ErrorPageComponent } from '../../shared/components/Page/error-page-component';

export const PasswordResetErrorPage = () => {
  const errorMessage =
    'The password reset link has expired. Please request a new password reset\n' +
    'link and promptly follow the password reset steps.';
  const title = 'Cannot Reset Password';
  return (
    <ErrorPageComponent
      errorMessage={errorMessage}
      title={title}
      returnUrl={LandingPageUrl}
    />
  );
};
