import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '@material-ui/icons/Link';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import {
  AutoMateLongIcon,
  InvPlusLogoIcon,
  CrmLongIcon,
  DealerFireLongIcon,
  IdmsLongIcon,
  PrecisePriceLongIcon,
  RevenueRadarLongIcon,
} from '@dealersocket/ds-ui-react/logos';
import { DsLogoProgress } from '@dealersocket/ds-ui-react/DsLogoProgress';
import { Page } from '../../../shared/components/Page';
import { axiosHelper } from '../../../shared/utils/axios-helper';
import { isAssociatedProduct } from '../../../shared/utils/helpers';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';
import * as CommonStore from '../../../store/Common';
import { ProductLinking } from './product-linking.component';
import { getProductFromEnum } from '../../../shared/enums';

const styles = (): StyleRules => {
  return {
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: 572,
    },
    highlight: {
      backgroundColor: 'white !important',
    },
    ieLinkedContainer: {
      alignItems: 'center',
      display: 'flex',
      left: 75,
      position: 'absolute',
      top: 45,
    },
    leftContent: {
      backgroundColor: '#f5f5f5',
      minHeight: 572,
      paddingBottom: 40,
      paddingTop: 40,
      width: 283,
    },
    linkedContainer: {
      alignItems: 'center',
      display: 'flex',
      left: 80,
      position: 'absolute',
      top: 45,
    },
    linkedIcon: {
      fontSize: 20,
      paddingRight: 2,
    },
    linkedItem: {
      paddingLeft: 0,
    },
    loader: { margin: 'auto' },
    loaderContainer: { display: 'flex', height: '100%' },
    menuItem: {
      backgroundColor: 'inherit',
      height: 76,
      paddingLeft: 40,
      paddingRight: 40,
      width: '100%',
    },
    unlinkedItem: { paddingLeft: 40 },
  };
};

type InternalProps = {
  classes: any;
  isSubmitting: boolean;
  replace?: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const LinkingComponent = (props: InternalProps) => {
  const { classes, webPSupported } = props;

  const title = 'Link your accounts';

  const iconProps = { width: '210px', height: '36px', viewBox: '0 0 210 36' };

  const [selectedProduct, setSelectedProduct] = useState({ type: '' });
  const [products, setProducts] = useState<any>([]);
  const [busy, setBusy] = useState(false);

  const getProducts = (selectUnlinkedProduct: boolean) => {
    setBusy(true);

    axiosHelper(
      '/accountSetup/getEnabledLinkingProducts',
      null,
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((result) => {
        const newProducts = result && Array.isArray(result) ? [...result] : [];
        if (newProducts.find((p) => p.type === 1)) {
          newProducts.push({
            type: -1,
            linked: newProducts.find((p) => p.type === 1).linked,
          });
        }

        if (newProducts.find((p) => p.type === 3)) {
          newProducts.push({
            type: -2,
            linked: newProducts.find((p) => p.type === 3).linked,
          });
        }

        newProducts.sort((p1, p2) => {
          if (getProductFromEnum(p1.type) > getProductFromEnum(p2.type))
            return 1;
          if (getProductFromEnum(p1.type) < getProductFromEnum(p2.type))
            return -1;
          return 0;
        });

        setProducts(newProducts);

        if (selectUnlinkedProduct) {
          setSelectedProduct(
            newProducts.find((p) => !p.linked) || newProducts[0]
          );
        } else {
          setSelectedProduct(
            newProducts.find((p) => p.type === selectedProduct.type)
          );
        }
      })
      .finally(() => {
        setBusy(false);
      });
  };

  useEffect(() => {
    getProducts(true);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getItem = (product: number) => {
    switch (product) {
      case 4:
        return <AutoMateLongIcon {...iconProps} />;
      case 1:
        return <CrmLongIcon {...iconProps} />;
      case 3:
        return <DealerFireLongIcon {...iconProps} />;
      case 5:
        return <IdmsLongIcon {...iconProps} />;
      case 2:
        return <InvPlusLogoIcon {...iconProps} />;
      case -2:
        return <PrecisePriceLongIcon {...iconProps} />;
      case -1:
        return <RevenueRadarLongIcon {...iconProps} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Page pageTitle={title} removePadding paperSize="lg">
        <div className={classes.root}>
          <Card>
            <div className={classes.contentContainer}>
              <div className={classes.leftContent}>
                {busy ? (
                  <div className={classes.loaderContainer}>
                    <div className={classes.loader}>
                      <DsLogoProgress />
                    </div>
                  </div>
                ) : (
                  <div className={classes.buttonContainer}>
                    {products.map((p) => (
                      <MenuItem
                        className={classNames(
                          {
                            [classes.linkedItem]:
                              !isAssociatedProduct(p) && p.linked,
                            [classes.unlinkedItem]:
                              isAssociatedProduct(p) || !p.linked,
                            [classes.highlight]:
                              selectedProduct.type === p.type,
                          },
                          classes.menuItem
                        )}
                        data-e2e={`${p.type}-menu-button`}
                        key={`${p.type}-menu-button`}
                        onClick={() => setSelectedProduct(p)}
                      >
                        {getItem(p.type)}
                        {p.linked && !isAssociatedProduct(p) && (
                          <div
                            className={
                              webPSupported
                                ? classes.linkedContainer
                                : classes.ieLinkedContainer
                            }
                          >
                            <LinkIcon
                              className={classes.linkedIcon}
                              color="primary"
                            />
                            <Typography color="primary">Linked</Typography>
                          </div>
                        )}
                      </MenuItem>
                    ))}
                  </div>
                )}
              </div>
              {selectedProduct && (
                <ProductLinking
                  selectedProduct={selectedProduct}
                  refreshFunc={getProducts}
                />
              )}
            </div>
          </Card>
        </div>
      </Page>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StyledPage = withStyles(styles)(LinkingComponent);

export const LinkingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledPage);
