import * as Common from './Common';
import * as Alert from './Alert';
import * as CommonForm from './CommonForm';

// The top-level state object
export type ApplicationStateType = {
  common: Common.CommonStateType;
  alert: Alert.AlertStateType;
  commonForm: CommonForm.CommonFormStateType;
};

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationStateType property type.
export const reducers = {
  common: Common.reducer,
  alert: Alert.reducer,
  commonForm: CommonForm.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkActionType<TAction> = {
  (
    dispatch: (action: TAction) => void,
    getState: () => ApplicationStateType
  ): void;
};
