import React from 'react';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Page } from '../shared/components/Page';
import { ChevronLeftCircleIcon } from '../shared/icons/chevron-left-circle-icon';
import { LandingPageUrl } from '../shared/constants';

export const ForbiddenPage = () => {
  const params = new URLSearchParams(window.location.search);
  const reason = params.get('reason') || 'Access Denied.';
  const traceId = params.get('trace_id');

  return (
    <Page>
      <Typography variant={TypographyVariants.H5} paragraph>
        403 - Forbidden
      </Typography>
      <Typography variant={TypographyVariants.Body1} paragraph>
        {reason}
      </Typography>
      <Typography variant={TypographyVariants.Body1} paragraph>
        {traceId ? `Request ID: ${traceId}` : ''}
      </Typography>
      <Button
        data-e2e="returnToLoginButton"
        onClick={() => window.location.replace(LandingPageUrl)}
      >
        <ChevronLeftCircleIcon />
        RETURN TO LOGIN
      </Button>
    </Page>
  );
};
