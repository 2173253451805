import React from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import PersonIcon from '@material-ui/icons/Person';
import FormGroup from '@material-ui/core/FormGroup';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import {
  required,
  validEmail,
  validEmailCustom,
} from '../../shared/utils/validation';
import { useAxios } from '../../shared/utils/use-axios';
import { axiosHelper } from '../../shared/utils/axios-helper';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';

const styles = (theme: Theme): StyleRules => {
  return {
    buttonStyle: {
      whiteSpace: 'nowrap',
      marginLeft: 12,
      padding: '10px 40px',
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    formGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    buttonGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
    textFieldIcon: { marginRight: 5 },
  };
};

export type UsernameVerificationProps = {
  email: string;
  isSubmitting: boolean;
  replace: any;
  isValidEmail: boolean;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

type InternalProps = {
  classes: JssClasses;
};

const UsernameVerification = (
  props: UsernameVerificationProps & InternalProps
) => {
  const { isSubmitting, classes, isValidEmail, webPSupported } = props;

  const [result, busy] = useAxios(
    '/accountSetup/getProposedUsername',
    undefined
  );

  const cancel = () => {
    props.replace('/accountSetup/usernameUpdate');
  };

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/accountSetup/sendUsernameVerification',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  return (
    <Page>
      <Typography
        data-e2e="emailVerificationTitle"
        variant={TypographyVariants.H5}
        paragraph
      >
        EMAIL VERIFICATION REQUIRED
      </Typography>
      <Typography
        data-e2e="emailVerificationBody"
        variant={TypographyVariants.Body1}
        paragraph
      >
        Please confirm or enter your email address, then click the SEND EMAIL
        button. We&apos;ll send a verification code to the specified email
        address that you can enter on the next page to verify it.
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: result.proposedUsername }}
        enableReinitialize
      >
        <FormGroup className={classes.formGroupStyle}>
          <FormTextField
            autoComplete="email"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="email"
            startAdornment={<PersonIcon className={classes.textFieldIcon} />}
            autoFocus
            required
            placeholder="Email"
            disabled={isSubmitting || busy}
            fullWidth={webPSupported}
            validate={[
              required,
              validEmailCustom('Please enter a valid email address'),
            ]}
          />
          <Button
            data-e2e="submitButton"
            disabled={isSubmitting || busy || !isValidEmail}
            type="submit"
            color="primary"
            className={classes.buttonStyle}
          >
            Send Email
          </Button>
        </FormGroup>
        <FormGroup className={classes.buttonGroupStyle}>
          <Button
            data-e2e="backButton"
            disabled={isSubmitting}
            onClick={cancel}
            className={classes.secondaryButtonStyle}
          >
            <ChevronLeftCircleIcon />
            Back
          </Button>
        </FormGroup>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const email = selector(state, 'email');
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isValidEmail: validEmail(email),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StylePage = withStyles(styles)(UsernameVerification);

export const UsernameVerificationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StylePage);
