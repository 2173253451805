import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/core/styles';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import {
  getProductDisplayName,
  getProductFromEnum,
} from '../../../shared/enums';
import { Form } from '../../../shared/components/Form';
import { required } from '../../../shared/utils/validation';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  description: {
    marginBottom: 30,
  },
  headerTitle: {
    marginBottom: 15,
  },
  textFieldIcon: {
    marginRight: 5,
  },
});

type ExternalProps = {
  isSubmitting: boolean;
  product: any;
  onForgotPassword: any;
  onSubmit: any;
  username: string;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators;

const UnlinkedProductComponent = (props: ExternalProps) => {
  const { isSubmitting, onForgotPassword, onSubmit, product, username } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.headerTitle}>
        <Typography variant={TypographyVariants.H5}>
          Use your existing credentials to sign in to each product you own.
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography variant={TypographyVariants.H3}>
          {getProductDisplayName(getProductFromEnum(product.type))}
        </Typography>
      </div>
      <div className={classes.container}>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <FormTextField
              autoComplete="username"
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                style: {
                  fontSize: 16,
                },
              }}
              disabled={isSubmitting}
              data-e2e="usernameField"
              name="username"
              label={`${getProductDisplayName(
                getProductFromEnum(product.type)
              )} username`}
              startAdornment={<PersonIcon className={classes.textFieldIcon} />}
              autoFocus
              validate={[required]}
              required
            />
            <FormTextField
              autoComplete="current-password"
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                style: {
                  fontSize: 16,
                },
              }}
              disabled={isSubmitting}
              data-e2e="passwordField"
              name="password"
              type="password"
              label={`${getProductDisplayName(
                getProductFromEnum(product.type)
              )} password`}
              startAdornment={<VpnKeyIcon className={classes.textFieldIcon} />}
              validate={[required]}
              required
            />
          </FormGroup>
          <div className={classes.buttonContainer}>
            <Button
              disabled={isSubmitting}
              data-e2e="forgotPasswordButton"
              onClick={() => {
                onForgotPassword(username);
              }}
            >
              Forgot Password
            </Button>
            <Button
              disabled={isSubmitting}
              data-e2e="linkAccountButton"
              type="submit"
              color="primary"
            >
              Link Account
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const username = selector(state, 'username');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    username,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const UnlinkedProduct: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnlinkedProductComponent);
