import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import * as AlertStore from '../../store/Alert';
import * as CommonStore from '../../store/CommonForm';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { axiosHelper } from '../../shared/utils/axios-helper';

const styles = (): StyleRules => {
  return {
    buttonStyle: {
      whitespace: 'nowrap',
      marginLeft: 12,
      padding: '10px 40px',
    },
    formGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
  };
};

type VerifyMfaProps = {
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

type InternalProps = {
  classes: JssClasses;
};

const VerifyMfa = (props: VerifyMfaProps & InternalProps) => {
  const { isSubmitting, classes } = props;

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/mfa/verify',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        <Typography variant={TypographyVariants.H5} paragraph>
          ENTER VERIFICATION CODE
        </Typography>
        <Typography variant={TypographyVariants.Body1} paragraph>
          A verification code was sent to your email address.
        </Typography>

        <FormGroup className={classes.formGroupStyle}>
          <FormTextField
            autoComplete="one-time-code"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="verificationCode"
            required
            fullWidth
            autoFocus
            placeholder="Enter the 6-digit code"
          />
          <Button
            data-e2e="submitButton"
            disabled={isSubmitting}
            className={classes.buttonStyle}
            type="submit"
            color="primary"
          >
            Verify
          </Button>
        </FormGroup>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  return { ...state.commonForm, ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

const StyledPage = withStyles(styles)(VerifyMfa);

export const VerifyMfaPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledPage);
