export const required = (value: any) =>
  value && value.replace(/\s/g, '').length > 0 ? undefined : 'Required';
export const requiredCustom = (message: string) => (value: any) => {
  return required(value) ? message : undefined;
};
export const validEmailCustom = (message: string = 'Invalid email address') => (
  value: string
) => {
  return validEmail(value) ? undefined : message;
};
export const validEmail = (value: any) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Valid'
    : undefined;

export const validCode = (isInvalid: boolean) => () => {
  return isInvalid
    ? 'The code entered is invalid. Please try again.'
    : undefined;
};
