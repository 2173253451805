import React from 'react';

import { withStyles, StyleRules } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const styles = (theme: Theme): StyleRules => {
  return {
    mobileVersionAlert: {
      width: 520,
      display: 'block',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      height: 'auto',
      backgroundColor: theme.palette.grey[400],
      padding: '10px 10px 30px 10px',
      marginBottom: -20,
    },
  };
};

type InternalProps = {
  classes: any;
};

type ExternalProps = {
  content: React.ReactNode;
};

const MobileNotificationComponent = (props: InternalProps & ExternalProps) => {
  const { classes, content } = props;
  return <div className={classes.mobileVersionAlert}>{content}</div>;
};

export const MobileNotification = withStyles(styles)(
  MobileNotificationComponent
);
