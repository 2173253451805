import React, { useState, useEffect } from 'react';
import { ErrorPageComponent } from '../../shared/components/Page/error-page-component';

export const ErrorPage = () => {
  const [requestId, setRequestId] = useState<string>();
  const [returnUrl, setReturnUrl] = useState<string>();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setRequestId(params.get('requestId') || '');

    if (params.get('returnUrl')) {
      setReturnUrl(params.get('returnUrl') || '');
    }
  }, []);

  const errorMessage = `The page could not be loaded. If the problem persists, contact support.\nRequest Id: ${requestId}`;
  const title = 'Error';
  return (
    <ErrorPageComponent
      errorMessage={errorMessage}
      returnUrl={returnUrl}
      title={title}
    />
  );
};
