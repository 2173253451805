import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';
import { useAxios } from '../../shared/utils/use-axios';
import { required, validEmailCustom } from '../../shared/utils/validation';
import { defaultInputProps } from '../../shared/constants';
import { getProductValue } from '../../shared/enums';
import { continueLogin } from '../../shared/utils/continue-login';

type AccountExistsProps = {
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  changeEmailButton: {
    whiteSpace: 'nowrap',
    marginBottom: 4,
  },
  forgotPassword: { marginRight: 10 },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  password: {
    marginBottom: 10,
  },
  textFieldIcon: {
    marginRight: 5,
  },
  usernameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AccountExists = (props: AccountExistsProps) => {
  const { isSubmitting, webPSupported, product } = props;
  const onSubmit = (values) => {
    const body = {
      username: values.email,
      password: values.password,
      product: product ? getProductValue(product) : null,
    };
    axiosHelper(
      '/accountSetup/linkExistingAccount',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    ).then(() =>
      continueLogin(
        props.setIsSubmitting,
        props.replace,
        props.setAlertMessage,
        props.clearAlertMessage
      )
    );
  };

  const [result, busy] = useAxios(
    '/accountSetup/getProposedUsername',
    undefined
  );

  const classes = useStyles();
  const changeEmail = () => {
    props.replace('/accountSetup/usernameUpdate');
  };

  const onForgotPassword = () => {
    axiosHelper(
      '/password/sendReset',
      {
        method: 'post',
        data: { username: result.proposedUsername },
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  return (
    <Page paperSize="lg">
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: result.proposedUsername }}
        enableReinitialize
      >
        <Typography data-e2e="title" paragraph variant={TypographyVariants.H4}>
          An account already exists for this email
        </Typography>
        <Typography
          data-e2e="contentBody"
          paragraph
          variant={TypographyVariants.Body1}
        >
          If this is you, sign in with your password to link this account under
          your email. If this is not you, select &quot;Change email&quot; to
          enter a different email address.
        </Typography>
        <div>
          <div className={classes.usernameContainer}>
            <FormTextField
              autoComplete="email"
              inputProps={defaultInputProps}
              name="email"
              label="Email"
              autoFocus
              required
              placeholder="Email"
              disabled
              fullWidth={webPSupported}
              startAdornment={<PersonIcon className={classes.textFieldIcon} />}
              validate={[
                required,
                validEmailCustom('Please enter a valid email address'),
              ]}
            />
            <Button
              onClick={changeEmail}
              disabled={busy || isSubmitting}
              data-e2e="changeEmailButton"
              className={classes.changeEmailButton}
            >
              Change Email
            </Button>
          </div>
          <FormTextField
            autoComplete="current-password"
            className={classes.password}
            inputProps={defaultInputProps}
            disabled={busy || isSubmitting}
            data-e2e="passwordField"
            name="password"
            type="password"
            label="Password"
            startAdornment={<VpnKeyIcon className={classes.textFieldIcon} />}
            fullWidth
            validate={[required]}
            required
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            disabled={busy || isSubmitting}
            data-e2e="continueButton"
            type="submit"
            color="primary"
          >
            Continue
          </Button>
          <Button
            disabled={busy || isSubmitting}
            className={classes.forgotPassword}
            data-e2e="forgotPasswordButton"
            onClick={onForgotPassword}
          >
            Forgot Password
          </Button>
        </div>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const AccountExistsPage: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountExists);
