import { Action, Reducer } from 'redux';
import { AppThunkActionType } from './index';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type CommonFormStateType = {
  dataModel: any;
  isSubmitting: boolean;
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

type SetIsSubmittingActionType = {
  isSubmitting: boolean;
  type: 'SET_IS_SUBMITTING';
};

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownActionType = SetIsSubmittingActionType;

export const actionCreators = {
  setIsSubmitting: (
    isSubmitting: boolean
  ): AppThunkActionType<KnownActionType> => (dispatch) => {
    dispatch({ type: 'SET_IS_SUBMITTING', isSubmitting });
  },
};

export const reducer: Reducer<CommonFormStateType> = (
  state: CommonFormStateType | undefined,
  incomingAction: Action
): CommonFormStateType => {
  const action = incomingAction as KnownActionType;
  switch (action.type) {
    case 'SET_IS_SUBMITTING':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
        dataModel: null,
      };
    default:
      // The following line guarantees that every action in the KnownAction union has been covered by a case above
      return state || { dataModel: {}, isSubmitting: false };
  }
};
