import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import * as AlertStore from '../../store/Alert';
import { Page } from '../../shared/components/Page';
import { ConnectedPasswordForm } from '../../shared/components/PasswordForm';
import { cancelLogin } from '../../shared/utils/cancel-login';
import * as CommonStore from '../../store/CommonForm';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { useAxios } from '../../shared/utils/use-axios';

type CreatePasswordProps = { replace: any } & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const CreatePassword = (props: CreatePasswordProps) => {
  const [result, busy] = useAxios('/account/getPartialLoginInfo', undefined);

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/password/create',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  const onCancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  return (
    <Page paperSize="lg">
      <Typography variant={TypographyVariants.H5} paragraph>
        CREATE PASSWORD
      </Typography>
      <ConnectedPasswordForm
        busy={busy}
        onSubmit={onSubmit}
        initialValues={{ username: result && result.username }}
        onCancel={onCancel}
        enableReinitialize
      />
    </Page>
  );
};

function mapStateToProps(state) {
  return { ...state.commonForm, ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const CreatePasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePassword);
