import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Page } from '../../shared/components/Page';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';

const styles = () => ({});

type ForgotPasswordSentProps = {
  classes: any;
  goBack: any;
};

const ForgotPasswordSent = (props: ForgotPasswordSentProps) => {
  return (
    <Page>
      <Typography
        variant={TypographyVariants.H5}
        data-e2e="forgotPasswordSentTitle"
        paragraph
      >
        PASSWORD RESET EMAIL SENT
      </Typography>
      <Typography
        variant={TypographyVariants.Body1}
        data-e2e="forgotPasswordSentContent"
        paragraph
      >
        A password reset email was sent to the email address associated with
        your account. Please click the link in the email to reset your password.
        If you do not receive an email within 20 minutes please contact your
        administrator.
      </Typography>
      <Button data-e2e="returnToLoginButton" onClick={() => props.goBack()}>
        <ChevronLeftCircleIcon />
        RETURN TO LOGIN
      </Button>
    </Page>
  );
};

const StyledForgotPasswordSent = withStyles(styles)(ForgotPasswordSent);

function mapStateToProps(state) {
  return { ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ goBack }, dispatch);
}

// Wire up the React component to the Redux store
export const ForgotPasswordSentPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledForgotPasswordSent);
