import React from 'react';
import { LandingPageUrl } from '../../shared/constants';
import { ErrorPageComponent } from '../../shared/components/Page/error-page-component';

export const EmailVerificationErrorPage = () => {
  const errorMessage =
    'The email verification link has expired. Please request a new verification link and promptly follow the email verification steps.';
  const title = 'Cannot Validate Email';
  return (
    <ErrorPageComponent
      errorMessage={errorMessage}
      title={title}
      returnUrl={LandingPageUrl}
    />
  );
};
