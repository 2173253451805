import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import * as AlertStore from '../../store/Alert';
import { Page } from '../../shared/components/Page';
import { ConnectedPasswordForm } from '../../shared/components/PasswordForm';
import { cancelLogin } from '../../shared/utils/cancel-login';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';
import { useAxios } from '../../shared/utils/use-axios';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { continueLogin } from '../../shared/utils/continue-login';

type SetAccountPasswordProps = {
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const SetAccountPassword = (props: SetAccountPasswordProps) => {
  const { product } = props;

  const [result, busy] = useAxios(
    '/accountSetup/getProposedUsername',
    undefined
  );

  const params = new URLSearchParams(window.location.search);
  const verificationCode = params.get('securityCode') || '';

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/accountSetup/setAccountPassword',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        localStorage.setItem(`${product}_username`, result.proposedUsername);
        localStorage.setItem('ent_username', result.proposedUsername);
        if (payload) {
          if (payload.isSpaRoute) {
            props.replace(payload.redirectUrl);
          } else {
            window.location.replace(payload.redirectUrl);
          }
        } else {
          continueLogin(
            props.setIsSubmitting,
            props.replace,
            props.setAlertMessage,
            props.clearAlertMessage
          );
        }
      })
      .catch(() => {});
  };

  const onCancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  return (
    <Page paperSize="lg">
      <Typography variant={TypographyVariants.H5} paragraph>
        RESET PASSWORD
      </Typography>
      <ConnectedPasswordForm
        busy={busy}
        onSubmit={onSubmit}
        initialValues={{ verificationCode, username: result.proposedUsername }}
        onCancel={onCancel}
        enableReinitialize
      />
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const SetAccountPasswordPage = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SetAccountPassword);
