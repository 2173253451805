import React from 'react';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Page } from '../shared/components/Page';

export const NotFoundPage = () => {
  return (
    <Page>
      <Typography variant={TypographyVariants.H5} paragraph>
        404 - Page Not Found
      </Typography>
      <Typography variant={TypographyVariants.Body1} paragraph>
        This page does not exist.
      </Typography>
    </Page>
  );
};
