import React from 'react';
import { withStyles } from '@material-ui/styles';
import { StyleRules } from '@material-ui/core/styles';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Page } from './page.component';
import { ChevronLeftCircleIcon } from '../../icons/chevron-left-circle-icon';

const styles = (): StyleRules => {
  return {
    upperCase: {
      textTransform: 'uppercase',
    },
  };
};

export type ErrorProps = {
  classes: any;
  errorMessage: string;
  returnUrl?: string;
  title: string;
};

const ErrorPage = (props: ErrorProps) => {
  const { classes, errorMessage, returnUrl, title } = props;

  return (
    <Page hideTitle isCentered>
      <Typography
        variant={TypographyVariants.H5}
        data-e2e="errorTitle"
        paragraph
        className={classes.upperCase}
      >
        {title}
      </Typography>
      <Typography
        variant={TypographyVariants.Body1}
        data-e2e="errorMessage"
        paragraph
      >
        {errorMessage}
      </Typography>
      {returnUrl ? (
        <Button
          data-e2e="returnToLoginButton"
          onClick={() => {
            window.location.replace(`${returnUrl}`);
          }}
          className={classes.upperCase}
        >
          <ChevronLeftCircleIcon />
          Return To Login
        </Button>
      ) : null}
    </Page>
  );
};

export const ErrorPageComponent = withStyles(styles)(ErrorPage);
