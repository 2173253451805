import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import {
  getProductDisplayName,
  getProductFromEnum,
} from '../../../shared/enums';

const useStyles = makeStyles({
  description: {
    marginBottom: 30,
  },
  headerTitle: {
    marginBottom: 15,
  },
});

type ExternalProps = {
  product: any;
};

export const AssociatedProducts = (props: ExternalProps) => {
  const { product } = props;

  const classes = useStyles();

  const getParentProduct = () => {
    switch (product.type) {
      case -2:
        return 'DealerFire';
      default:
        return 'CRM';
    }
  };

  return (
    <>
      <div className={classes.headerTitle}>
        <Typography variant={TypographyVariants.H4}>
          {getProductDisplayName(getProductFromEnum(product.type))}
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography>
          Connect your {getProductDisplayName(getProductFromEnum(product.type))}{' '}
          account by linking your {getParentProduct()} login.
        </Typography>
      </div>
    </>
  );
};
