import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { axiosApi } from '@dealersocket/react-common';
import * as AlertStore from '../../store/Alert';
import { Page } from '../../shared/components/Page';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';

type LogoutProps = {
  clientName: string;
} & typeof AlertStore.actionCreators;

function Logout({ setAlertMessage }: LogoutProps) {
  const [postLogoutRedirectUri, setPostLogoutRedirectUri] = useState();
  const [clientName, setClientName] = useState('');
  const [signOutIframeUrl, setSignOutIframeUrl] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const logoutId = params.get('logoutId');

    const body = {
      logoutId,
    };

    axiosApi('/account/logout', {
      method: 'post',
      data: body,
    })
      .then((payload) => {
        setPostLogoutRedirectUri(payload.postLogoutRedirectUri);
        setClientName(payload.clientName);
        setSignOutIframeUrl(payload.signOutIframeUrl);
        if (payload.postLogoutRedirectUri) {
          setTimeout(() => {
            window.location.replace(payload.postLogoutRedirectUri);
          }, 10000);
        }
      })
      .catch((error) => {
        setAlertMessage(error.response.data || error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] as any);

  return (
    <Page pageTitle={<span>You have been logged out</span>}>
      {postLogoutRedirectUri ? (
        <div>
          <Typography variant={TypographyVariants.Subtitle1} paragraph>
            If you are not automatically redirected, you can click below to
            return to the login page for <strong>{clientName}</strong>.
          </Typography>
          <Button data-e2e="returnToLoginButton" href={postLogoutRedirectUri}>
            <ChevronLeftCircleIcon />
            RETURN TO LOGIN
          </Button>
        </div>
      ) : null}
      {signOutIframeUrl && (
        <iframe
          title="signOutIframe"
          width="0"
          height="0"
          style={{ display: 'none' }}
          src={signOutIframeUrl}
        />
      )}
    </Page>
  );
}

function mapStateToProps(state: any) {
  return { ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ ...AlertStore.actionCreators }, dispatch);
}

export const LogoutPage = connect(mapStateToProps, mapDispatchToProps)(Logout);
