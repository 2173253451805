import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import FormGroup from '@material-ui/core/FormGroup';
import { axiosApi } from '@dealersocket/react-common';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from '../../../shared/components/Page';
import { Form } from '../../../shared/components/Form';
import { continueLogin } from '../../../shared/utils/continue-login';
import {
  getProductDisplayName,
  getProductFromEnum,
} from '../../../shared/enums';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';
import * as CommonStore from '../../../store/Common';

type LinkingSuccessProps = {
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const useStyles = makeStyles({
  button: {
    alignSelf: 'flex-end',
  },
  img: {
    backgroundImage: (props: any) => props.backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 0,
    paddingTop: '62%',
    width: '100%',
  },
  title: {
    fontWeight: 500,
  },
});

const LinkingSuccess = (props: LinkingSuccessProps) => {
  const { isSubmitting, webPSupported } = props;

  const [username, setUsername] = useState('');
  const [linkedProducts, setLinkedProducts] = useState();
  const onSubmit = () => {
    continueLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  useEffect(() => {
    Promise.all([
      axiosApi('/account/getPartialLoginInfo', {}),
      axiosApi('/accountSetup/getEnabledLinkingProducts', {}),
    ]).then(function getResults(results) {
      if (results[0]) {
        setUsername(results[0].username);
      }

      if (results[1] && Array.isArray(results[1])) {
        const linkedProductsArray = results[1].filter(
          (product) => product.linked
        );

        const linkedProductsMappedArray = linkedProductsArray
          .map((product) =>
            getProductDisplayName(getProductFromEnum(product.type))
          )
          .sort();

        const productsList = linkedProductsMappedArray.map((product) => {
          return <strong>{product}</strong>;
        });

        const lastItem = productsList.pop();

        if (productsList.length === 0) {
          setLinkedProducts(<span>{lastItem} account</span>);
        }

        if (productsList.length === 1) {
          setLinkedProducts(
            <span>
              {productsList[0]} and {lastItem} accounts
            </span>
          );
        }

        if (productsList.length > 1) {
          setLinkedProducts(
            <span>
              {productsList.map((product) => {
                return <span key={`product-${product}`}>{product}, </span>;
              })}{' '}
              and {lastItem} accounts
            </span>
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] as any);

  const styleProps = {
    backgroundImage: `url(images/linking_confirmation_instruction_image.${
      webPSupported ? 'webp' : 'jpg'
    })`,
  };

  const classes = useStyles(styleProps);

  return (
    <Page
      paperSize="lg"
      pageTitle={<span className={classes.title}>Congratulations!</span>}
    >
      <Form onSubmit={onSubmit}>
        <Typography data-e2e="title" paragraph variant={TypographyVariants.H4}>
          You have successfully updated your account.
        </Typography>
        <Typography
          data-e2e="contentBody"
          paragraph
          variant={TypographyVariants.Body1}
        >
          Remember to use <strong>{username}</strong> and your new password to
          access your {linkedProducts}.
        </Typography>
        <div className={classes.img} />
        <FormGroup>
          <Button
            className={classes.button}
            color="primary"
            data-e2e="continueButton"
            disabled={isSubmitting}
            type="submit"
          >
            Got it
          </Button>
        </FormGroup>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const LinkingSuccessPage: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkingSuccess);
