import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { withStyles } from '@material-ui/styles';
import { useAxios } from '../../shared/utils/use-axios';

const styles = (theme: Theme) => {
  return {
    paperBorder: {
      borderRadius: 10,
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
  };
};

const useStyles = makeStyles({
  marketingBox: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    height: 'calc(100% - 85px)',
    marginRight: -15,
    marginTop: 85,
    width: 440,
    // CSS property
    backgroundImage: (props: any) => `url(${props.backgroundImage})`,
  },
});

type InternalProps = {
  classes: any;
};

const MarketingBannerComponent = (props: InternalProps) => {
  const { classes } = props;
  const [source, setSource] = React.useState('');
  const [dest, setDest] = React.useState('');

  const [result] = useAxios('/account/GetMarketingBanner', undefined);

  React.useEffect(() => {
    if (result && result.imageSourceUrl) {
      setSource(result.imageSourceUrl);
    }
    if (result && result.destinationTargetUrl) {
      setDest(result.destinationTargetUrl);
    }
  }, [result]);

  const styleProps = {
    backgroundImage: source,
  };
  const dynamicClasses = useStyles(styleProps);

  return (
    <a
      data-e2e="marketingBannerRedirect"
      href={dest}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Paper
        data-e2e="marketingBannerImage"
        elevation={10}
        className={dynamicClasses.marketingBox}
        classes={{ rounded: classes.paperBorder }}
      />
    </a>
  );
};

export const MarketingBanner = withStyles(styles)(MarketingBannerComponent);
