import React from 'react';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { withStyles, StyleRules } from '@material-ui/styles';
import { MobileNotification } from './mobile-notification.component';
import { CrmStackedLogoIcon } from '../../icons/crm-stacked-logo-icon';

const styles = (): StyleRules => {
  return {
    typographyContainer: {
      color: 'black',
    },
    iconContainer: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'baseline',
    },
    icon: {
      '&:visited': { textDecoration: 'none', color: 'initial' },
    },
  };
};

type InternalProps = {
  classes: any;
};

const CrmMobilePilotNotificationComponent = (props: InternalProps) => {
  const { classes } = props;

  return (
    <div>
      <MobileNotification
        content={
          <div className={classes.typographyContainer}>
            <Typography color="inherit" variant={TypographyVariants.Body1}>
              This legacy app is no longer supported. Please download the new
              Mobile CRM app, <b>&quot;CRM by DealerSocket&quot;</b> today to
              start enjoying the new and improved experience. Questions, please
              contact support or your dedicated CSM.
            </Typography>
            <div
              style={{
                marginTop: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <CrmStackedLogoIcon />
            </div>
          </div>
        }
      />
    </div>
  );
};

export const CrmMobilePilotNotification = withStyles(styles)(
  CrmMobilePilotNotificationComponent
);
