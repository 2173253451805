export function getProductDisplay(product?: string) {
  switch (product && product.toLowerCase()) {
    case 'crm':
      return 'CRM';
    case 'inv':
      return 'Inventory+';
    case 'web':
      return 'DealerFire';
    case 'automate':
      return 'Auto/Mate';
    case 'idms':
      return 'IDMS';
    default:
      return 'DealerSocket';
  }
}

export function getProductDisplayName(product: string) {
  switch (product && product.toLowerCase()) {
    case 'automate':
      return 'Auto/Mate';
    case 'crm':
      return 'CRM';
    case 'dealerfire':
      return 'DealerFire';
    case 'idms':
      return 'IDMS';
    case 'inv':
      return 'Inventory+';
    case 'preciseprice':
      return 'PrecisePrice';
    case 'revenueradar':
      return 'RevenueRadar';
    default:
      return 'Enterprise';
  }
}

export function getProductValue(product: string) {
  switch (product && product.toLowerCase()) {
    case 'crm':
      return 1;
    case 'inv':
      return 2;
    case 'dealerfire':
      return 3;
    case 'automate':
      return 4;
    case 'idms':
      return 5;
    default:
      return 0;
  }
}

export function getProductFromEnum(product: number) {
  switch (product) {
    case 1:
      return 'crm';
    case 2:
      return 'inv';
    case 3:
      return 'dealerFire';
    case 4:
      return 'automate';
    case 5:
      return 'idms';

    case -1:
      return 'revenueRadar';
    case -2:
      return 'precisePrice';
    default:
      return 'enterprise';
  }
}
