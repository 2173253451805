import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, StyleRules } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import * as AlertStore from '../../../store/Alert';

const styles = (theme: Theme): StyleRules => ({
  content: {
    backgroundColor: 'white',
    color: 'black',
  },
  error: {
    borderLeft: 'solid 5px #cf5c60',
  },
  warning: {
    borderLeft: 'solid 5px #f7aa09',
  },
  topPosition: {
    top: 8,
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'baseline',
  },
});

type InternalProps = {
  classes: any;
};

type AlertComponentProps = InternalProps &
  AlertStore.AlertStateType &
  typeof AlertStore.actionCreators;

const AlertCore = (
  props: AlertComponentProps
): React.ReactElement<AlertComponentProps> => {
  const {
    classes,
    open,
    message,
    isWarning,
    showReload,
    clearAlertMessage,
  } = props;

  function handleClose(event: React.SyntheticEvent, reason: string) {
    if (reason === 'clickaway') {
      return;
    }

    clearAlertMessage();
  }

  function onClick() {
    clearAlertMessage();
  }

  return (
    <Snackbar
      classes={{ anchorOriginTopCenter: classes.topPosition }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open || false}
      onClose={handleClose}
      autoHideDuration={isWarning ? 6000 : undefined}
    >
      <SnackbarContent
        className={`${classes.content} ${
          isWarning ? classes.warning : classes.error
        }`}
        aria-describedby="message-id"
        message={
          <span className={classes.container}>
            <Typography id="message-id" variant={TypographyVariants.Body1}>
              <strong>{isWarning ? 'WARNING' : 'ERROR'}: </strong> {message}
            </Typography>
            {showReload && (
              <Button
                data-e2e="reloadButton"
                onClick={() => window.location.reload(false)}
              >
                Reload
              </Button>
            )}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClick}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

function mapStateToProps(state: any) {
  return { ...state.alert };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ ...AlertStore.actionCreators }, dispatch);
}

const StyledAlert = withStyles(styles)(AlertCore);

export const Alert: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledAlert);
