import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { axiosHelper } from '../../../shared/utils/axios-helper';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';
import * as CommonStore from '../../../store/Common';
import { isAssociatedProduct } from '../../../shared/utils/helpers';
import { AssociatedProducts } from './associated-products.component';
import { LinkedProductComponent } from './linked-product.component';
import { ForgotPassword } from './forgot-password.component';
import { VerificationCodeInput } from './verification-code-input.component';
import { UnlinkedProduct } from './unlinked-products.component';
import { continueLogin } from '../../../shared/utils/continue-login';

const useStyles = makeStyles({
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  continueButton: {
    alignSelf: 'flex-end',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px',
    width: '60%',
  },
  spacerDiv: {
    flexGrow: 1,
  },
  bottomContainerDiv: {
    textAlign: 'right',
  },
});

type ProductLinkingComponentProps = {
  refreshFunc: any;
  isSubmitting: boolean;
  selectedProduct: any;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const ProductLinkingComponent = (props: ProductLinkingComponentProps) => {
  const { selectedProduct, refreshFunc, isSubmitting } = props;

  const classes = useStyles();

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [username, setUsername] = useState();

  React.useEffect(() => {
    setShowForgotPassword(false);
    setShowCodeInput(false);
    setUsername('');
  }, [selectedProduct]);

  const onCancel = () => {
    axiosHelper(
      `/accountSetup/skipLinking?doNotAskAgain=false`,
      {
        method: 'post',
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const onContinue = () => {
    axiosHelper(
      '/accountSetup/completeLinking',
      {
        method: 'post',
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };

  const onSendEmailSubmit = (values: any): any => {
    const body = {
      ...values,
      product: selectedProduct.type,
    };

    axiosHelper(
      '/accountSetup/sendForgotPasswordCode',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        setShowCodeInput(true);
        setShowForgotPassword(false);
        setUsername(body.username);
      })
      .catch(() => {});
  };

  const onVerifyCodeSubmit = (values: any): any => {
    const body = {
      ...values,
      username,
      product: selectedProduct.type,
    };

    return axiosHelper(
      '/accountSetup/verifyCode',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    ).then(() => {
      refreshFunc(false);
      setShowCodeInput(false);
    });
  };

  const onLinkAccountSubmit = (values: any): any => {
    const body = {
      ...values,
      product: selectedProduct.type,
    };

    axiosHelper(
      '/accountSetup/link',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        refreshFunc(false);
      })
      .catch(() => {});
  };

  const getContent = () => {
    if (isAssociatedProduct(selectedProduct)) {
      return <AssociatedProducts product={selectedProduct} />;
    }

    if (selectedProduct.linked) {
      return <LinkedProductComponent product={selectedProduct} />;
    }

    if (showForgotPassword) {
      return (
        <ForgotPassword
          product={selectedProduct}
          onSubmit={onSendEmailSubmit}
          onCancel={() => {
            setShowForgotPassword(false);
          }}
          username={username}
        />
      );
    }

    if (showCodeInput) {
      return (
        <VerificationCodeInput
          product={selectedProduct}
          onSubmit={onVerifyCodeSubmit}
          onCancel={() => {
            setShowCodeInput(false);
          }}
          username={username}
        />
      );
    }

    return (
      <UnlinkedProduct
        product={selectedProduct}
        onSubmit={onLinkAccountSubmit}
        onForgotPassword={(proposedUsername: string) => {
          setUsername(proposedUsername);
          setShowForgotPassword(true);
        }}
      />
    );
  };

  return (
    <div className={classes.rightContent}>
      {getContent()}
      {!showForgotPassword && !showCodeInput && (
        <>
          <div className={classes.spacerDiv} />
          <div className={classes.bottomContainer}>
            <Typography paragraph>
              Need more time? You can always link your accounts later in your
              DealerSocket profile.
            </Typography>
            <div className={classes.bottomContainerDiv}>
              <Button
                data-e2e="cancelLinkButton"
                className={classes.continueButton}
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                data-e2e="completeLinkButton"
                className={classes.continueButton}
                disabled={isSubmitting}
                onClick={onContinue}
              >
                Finished
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const ProductLinking: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductLinkingComponent);
