import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from './shared/components/Layout';
import { ForbiddenPage } from './area/forbidden-page';
import { ClientErrorPage } from './area/client-error';
import { NotFoundPage } from './area/not-found-page';
import { LoginExpiredPage } from './area/login-expired-page';
import { LoginPage } from './area/account/login-page';
import { LogoutPage } from './area/account/logout-page';
import { UsernameUpdatePage } from './area/accountSetup/username-update-page';
import { AccountExistsPage } from './area/accountSetup/account-exists-page';
import { UsernameVerificationPage } from './area/accountSetup/username-verification-page';
import { VerifyUsernamePage } from './area/accountSetup/verify-username-page';
import { SetAccountPasswordPage } from './area/accountSetup/set-account-password-page';
import { LinkingIntroPage } from './area/accountSetup/linking/linking-intro-page';
import { AccountSetupSuccessPage } from './area/accountSetup/account-setup-success-page';
import { LinkingSuccessPage } from './area/accountSetup/linking/linking-success-page';
import { EmailVerificationPage } from './area/email/verification-page';
import { EmailVerificationErrorPage } from './area/email/email-verification-error-page';
import { ErrorPage } from './area/error/error-page';
import { VerifyEmailPage } from './area/email/verify-page';
import { EmailVerificationSuccessPage } from './area/email/verification-success-page';
import { PasswordResetErrorPage } from './area/password/password-reset-error-page';
import { ForgotPasswordPage } from './area/password/forgot-page';
import { ForgotPasswordSentPage } from './area/password/forgot-sent-page';
import { PasswordResetPage } from './area/password/reset-page';
import { PasswordResetSuccessPage } from './area/password/reset-success-page';
import { ChangePasswordPage } from './area/password/change-page';
import { ChangePasswordSuccessPage } from './area/password/change-success-page';
import { CreatePasswordPage } from './area/password/create-page';
import { CreatePasswordSuccessPage } from './area/password/create-success-page';
import { VerifyPasswordResetPage } from './area/password/verify-page';
import { AcceptEulaPage } from './area/eula/accept-eula-page';
import { UserImpersonationPage } from './area/admin/user-impersonation-page';
import { VerifyMfaPage } from './area/mfa/verify-page';
import { getBackgroundImage } from './shared/utils/background-image';
import { PartialLoginRoute } from './shared/components/PartialLoginRoute';
import { LinkingPage } from './area/accountSetup/linking/linking-page';
import { LinkedAccountsPage } from './area/admin/linked-accounts-page';

export const routes = (
  <Layout backgroundImage={getBackgroundImage()}>
    <Switch>
      <Route
        path="*/spa"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(/\/spa/, ''),
            }}
          />
        )}
      />
      <Route path="*/account/login" component={LoginPage} />
      <Route path="*/account/logout" component={LogoutPage} />
      <PartialLoginRoute
        path="*/accountSetup/usernameUpdate"
        component={UsernameUpdatePage}
      />
      <PartialLoginRoute
        path="*/accountSetup/accountExists"
        component={AccountExistsPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/usernameVerification"
        component={UsernameVerificationPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/verifyUsername"
        component={VerifyUsernamePage}
      />
      <PartialLoginRoute
        path="*/accountSetup/setAccountPassword"
        component={SetAccountPasswordPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/linkingIntro"
        component={LinkingIntroPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/linking"
        component={LinkingPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/accountSetupSuccess"
        component={AccountSetupSuccessPage}
      />
      <PartialLoginRoute
        path="*/accountSetup/linkingSuccess"
        component={LinkingSuccessPage}
      />
      <PartialLoginRoute
        path="*/admin/userImpersonation"
        component={UserImpersonationPage}
      />
      <PartialLoginRoute
        path="*/admin/linkedAccounts"
        component={LinkedAccountsPage}
      />
      <PartialLoginRoute
        path="*/email/verification"
        component={EmailVerificationPage}
      />
      <Route path="*/email/error" component={EmailVerificationErrorPage} />
      <PartialLoginRoute path="*/email/verify" component={VerifyEmailPage} />
      <PartialLoginRoute
        path="*/email/verificationSuccess"
        component={EmailVerificationSuccessPage}
      />
      <Route
        path="*/email/linkVerificationSuccess"
        render={() => <EmailVerificationSuccessPage fromLink />}
      />
      <Route path="*/error" component={ErrorPage} />
      <PartialLoginRoute path="*/eula/required" component={AcceptEulaPage} />
      <Route path="*/password/error" component={PasswordResetErrorPage} />
      <Route path="*/password/forgot" component={ForgotPasswordPage} />
      <Route path="*/password/resetSent" component={ForgotPasswordSentPage} />
      <Route path="*/password/verify" component={VerifyPasswordResetPage} />
      <Route path="*/password/reset" component={PasswordResetPage} />
      <Route
        path="*/password/resetSuccess"
        component={PasswordResetSuccessPage}
      />
      <Route path="*/password/change" component={ChangePasswordPage} />
      <Route
        path="*/password/changeSuccess"
        component={ChangePasswordSuccessPage}
      />
      <PartialLoginRoute
        path="*/password/create"
        component={CreatePasswordPage}
      />
      <PartialLoginRoute
        path="*/password/createSuccess"
        component={CreatePasswordSuccessPage}
      />
      <PartialLoginRoute path="*/mfa/verify" component={VerifyMfaPage} />
      <Route path="*/forbidden" component={ForbiddenPage} />
      <Route path="*/account/accessDenied" component={ForbiddenPage} />
      <Route path="*/account/clientError" component={ClientErrorPage} />
      <Route path="*/loginExpired" component={LoginExpiredPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </Layout>
);
