import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';
import { useAxios } from '../../shared/utils/use-axios';

type VerificationSuccessProps = {
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const useStyles = makeStyles({
  button: {
    alignSelf: 'flex-end',
  },
  img: {
    backgroundImage: (props: any) => props.backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 0,
    paddingTop: '38.25%',
    width: '100%',
  },
  title: {
    fontWeight: 500,
  },
});

const AccountSetupSuccess = (props: VerificationSuccessProps) => {
  const { isSubmitting, webPSupported } = props;

  const onSubmit = () => {
    continueLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  const [result] = useAxios('/accountSetup/getProposedUsername', undefined);

  const styleProps = {
    backgroundImage: `url(images/email_confirmation_instruction_image.${
      webPSupported ? 'webp' : 'jpg'
    })`,
  };

  const classes = useStyles(styleProps);

  return (
    <Page
      paperSize="lg"
      pageTitle={<span className={classes.title}>Congratulations!</span>}
    >
      <Form onSubmit={onSubmit}>
        <Typography data-e2e="title" paragraph variant={TypographyVariants.H4}>
          You have successfully updated your account.
        </Typography>
        <Typography
          data-e2e="contentBody"
          paragraph
          variant={TypographyVariants.Body1}
        >
          Remember to use <strong>{result.proposedUsername}</strong> and your
          new password when logging in next time.
        </Typography>
        <div className={classes.img} />
        <FormGroup>
          <Button
            className={classes.button}
            color="primary"
            data-e2e="continueButton"
            disabled={isSubmitting}
            type="submit"
          >
            Got it
          </Button>
        </FormGroup>
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      replace,
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const AccountSetupSuccessPage: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSetupSuccess);
