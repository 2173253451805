import { axiosApi } from '@dealersocket/react-common';
import { useEffect, useState } from 'react';

export function useAxios(url: string, options: any): any {
  const [result, setResult] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [error, setError] = useState(null);

  const callAxiosApi = () =>
    axiosApi(url, options)
      .then((response) => {
        setResult(response);
        setIsBusy(false);
      })
      .catch((errorResponse) => {
        setError(errorResponse);
        setIsBusy(false);
      });

  useEffect(() => {
    callAxiosApi();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return [result, isBusy, error];
}
