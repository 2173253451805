import React from 'react';
import { withStyles } from '@material-ui/styles';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import { StyleRules } from '@material-ui/core/styles';

const styles = (): StyleRules => {
  return {
    container: { margin: '30px 30px 30px 30px' },
    header: { paddingBottom: '20px', textAlign: 'center' },
    content: {
      padding: '0px 50px 0px 50px',
    },
  };
};
type EulaContentProps = {
  classes: JssClasses;
  ref: any;
  header: any;
  mainEulaContent: any;
};

// Refs are not available for stateless components. For "react-to-print" to work only Class based components can be printed
// eslint-disable-next-line react/prefer-stateless-function
class EulaContent extends React.Component<EulaContentProps> {
  render() {
    const { classes, mainEulaContent, header } = this.props;
    return (
      <div>
        <div className={classes.container}>
          <div className={classes.header}>{header}</div>
          <div className={classes.content}>{mainEulaContent}</div>
        </div>
      </div>
    );
  }
}

export const StyledEulaContent = withStyles(styles)(EulaContent);
