import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { reducer as formReducer } from 'redux-form';
import { ApplicationStateType as ApplicationState, reducers } from '.';

export const configureStore = (
  history: History,
  initialState?: ApplicationState
) => {
  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
    form: formReducer,
  });

  const enhancers: Function[] = [];
  const windowIfDefined =
    typeof window === 'undefined' ? null : (window as any);

  /* eslint-disable no-underscore-dangle */
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }
  /* eslint-enable */

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
};
