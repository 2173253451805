import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { TextField } from '@dealersocket/ds-ui-react/fields/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
  getProductDisplayName,
  getProductFromEnum,
} from '../../../shared/enums';

const useStyles = makeStyles({
  description: {
    marginBottom: 30,
  },
  headerTitle: {
    marginBottom: 15,
  },
  textFieldIcon: {
    marginRight: 5,
  },
});

type ExternalProps = {
  product: any;
};

export const LinkedProductComponent = (props: ExternalProps) => {
  const { product } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.headerTitle}>
        <Typography variant={TypographyVariants.H4}>
          {getProductDisplayName(getProductFromEnum(product.type))} has been
          linked
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography>
          Select another product from the left to link into your DealerSocket
          Account.
        </Typography>
      </div>
      <TextField
        inputProps={{
          style: {
            fontSize: 16,
          },
        }}
        disabled
        fullWidth
        data-e2e="usernameField"
        name="username"
        label={`${getProductDisplayName(
          getProductFromEnum(product.type)
        )} username`}
        value={product.username}
        startAdornment={<PersonIcon className={classes.textFieldIcon} />}
      />
    </>
  );
};
