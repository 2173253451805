/* eslint-disable max-lines */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { replace } from 'connected-react-router';
import { withStyles, StyleRules } from '@material-ui/styles';
import FormGroup from '@material-ui/core/FormGroup';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { FormCheckboxField } from '@dealersocket/ds-ui-react/form/fields/FormCheckboxField';
import * as AlertStore from '../../store/Alert';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { PrintableEulaContent } from './printable-eula-content';
import * as CommonStore from '../../store/CommonForm';
import { axiosHelper } from '../../shared/utils/axios-helper';

const styles = (theme: Theme): StyleRules => ({
  acceptEula: {
    [theme.breakpoints.between('sm', 'md')]: {
      width: 230,
    },
  },
  acceptLabel: {
    paddingLeft: 5,
  },
  agreementGroup: {
    marginTop: 20,
  },
  button: {
    height: 40,
    marginLeft: 10,
    width: 120,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    marginRight: 15,
    maxHeight: 'calc(40vh)',
    overflow: 'auto',
  },
  formCheckbox: {
    marginLeft: 5,
  },
  formGroupStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  header: {
    textAlign: 'left',
  },
  printableEulaContainer: {
    float: 'right',
  },
});

type AcceptEulaProps = {
  acceptedEula: boolean;
  classes: any;
  isSubmitting: boolean;
  replace: any;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const AcceptEula = (props: AcceptEulaProps) => {
  const { acceptedEula, isSubmitting, classes } = props;

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  const onSubmit = () => {
    const body = {};

    axiosHelper(
      '/eula/accept',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then(() => {
        continueLogin(
          props.setIsSubmitting,
          props.replace,
          props.setAlertMessage,
          props.clearAlertMessage
        );
      })
      .catch(() => {});
  };
  const header = (
    <div>
      <Typography variant={TypographyVariants.H5}>
        DEALERSOCKET, INC.
      </Typography>
      <Typography variant={TypographyVariants.H5}>
        END USER LICENSE AGREEMENT
      </Typography>
      <Typography paragraph>Last updated: October 2022</Typography>
    </div>
  );
  const eulaContent = (
    <div>
      <Typography paragraph>
        In order to use the software products and applications provided to you
        by DealerSocket (collectively, the “Software”), you must first agree to
        the terms and conditions in the End User License Agreement (the “EULA”).
        You may not use the Software if you do not accept the terms and
        conditions of the EULA. You can accept the EULA by (1) clicking to
        accept or agree to the EULA, where this option is made available to you
        in the user interface for the Software; or (2) by actually using the
        Software. In this case, you understand and agree that DealerSocket will
        treat your use of the Software as acceptance of the terms and conditions
        of the EULA from that point onwards. If any materials are available for
        downloading, access or other use from the Software with their own
        license terms, conditions and notices, you will also be subject to such
        license terms, conditions and notices.
      </Typography>

      <Typography paragraph>
        DealerSocket may update the EULA from time to time. You understand and
        agree that if you use the Software after the date on which the EULA
        changed, DealerSocket will treat your use as acceptance of the updated
        EULA.
      </Typography>

      <Typography paragraph>
        Subject to your compliance with all of the terms and conditions of the
        EULA, the current Terms of Service, and any other agreement relating to
        the Software, DealerSocket grants to you a revocable, non-exclusive,
        non-assignable, non-sublicensable and non-transferable license to use
        the Software solely in support of your internal business operations,
        subject to the usage restrictions, overages, or other limitations
        contained in the applicable order form, master services agreement, or
        other agreement, or as otherwise listed in the product descriptions and
        current Terms of Service available at
        https://www.dealersocket.com/licensing.
      </Typography>

      <Typography paragraph>
        You may not (and you may not permit anyone else to) copy, modify, create
        a derivative work of, reverse engineer, decompile or otherwise attempt
        to extract the source code of the Software or any part thereof, unless
        this is expressly permitted or required by law. The Software, and any
        applications made available through the Software, are licensed, not
        sold, to you. All rights not expressly granted to you in are reserved by
        us, or the third-party vendor, as applicable. DealerSocket may make
        changes to the Software, or any product or service offered by
        DealerSocket or to the applicable prices, at any time, without notice.
      </Typography>

      <Typography paragraph>
        You are solely responsible for keeping your account information
        (including, without limitation, your user ID and password) confidential,
        and for any and all activity that occurs through your account as a
        result of your failing to keep this information confidential. You agree
        to notify DealerSocket immediately of any unauthorized use of your
        account, and of any other breach of security. You may be held liable for
        losses incurred by DealerSocket or any other user of the Software due to
        someone else using your account as a result of your failing to keep your
        account information secure and confidential. You may not share your
        account information with anyone, and may not use anyone else’s account
        information for the Software at any time. DealerSocket cannot and will
        not be liable for any loss or damage arising from your failure to comply
        with these obligations.
      </Typography>

      <Typography paragraph>
        By installing or using the Software, you agree to allow DealerSocket to
        collect and use your data in accordance with DealerSocket’s privacy
        policy available at dealersocket.com.
      </Typography>
      <Typography paragraph>
        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SOFTWARE IS AT YOUR
        SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
        SOFTWARE IS PROVIDED “AS IS” AND “AS AVAILABLE,” WITH ALL FAULTS AND
        FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND DEALERSOCKET HEREBY
        DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE SOFTWARE AND
        ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT
        LIMITED TO, THE IMPLIED WARRANTIES MERCHANTABILITY, OF SATISFACTORY
        QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET
        ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR
        WRITTEN INFORMATION OR ADVICE GIVEN BY DEALERSOCKET OR ITS AUTHORIZED
        REPRESENTATIVE CREATES A WARRANTY. YOU ASSUME TOTAL RESPONSIBILITY FOR
        YOUR USE OF THE SOFTWARE AND ANY RELATED PRODUCTS. TO THE EXTENT NOT
        PROHIBITED BY LAW, IN NO EVENT WILL DEALERSOCKET BE LIABLE FOR PERSONAL
        INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES
        WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS,
        LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR
        LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE
        SOFTWARE, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY
        (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF DEALERSOCKET HAS BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES. In no event will DealerSocket’s
        total liability to you for all damages damages (other than as may be
        required by applicable law in cases involving personal injury) exceed
        the amount of $100.00. The foregoing limitations will apply even if the
        above stated remedy fails of its essential purpose.
      </Typography>

      <Typography paragraph>
        <b>
          <u>Users of SocketTalk</u>
        </b>
        <br />
        You agree not to send any information protected under the
        Gramm-Leach-Bliley Act through SocketTalk.
        <br />
        <b>
          <u>Users of DealerSocket Email</u>
        </b>
        <br />
        You agree not to send any information protected under the
        Gramm-Leach-Bliley Act through DealerSocket Email.
      </Typography>

      <Typography paragraph>
        Please note that the use of the Software may be subject to data
        protection laws or regulations in certain jurisdictions. You are
        responsible for ensuring that your use of the Software is in accordance
        with such laws or regulations. You must comply with all U.S. or other
        export and re-export restrictions that may apply to goods, software
        (including the Software), technology, and services, and you will not
        transfer, or encourage, assist, or authorize the transfer of the
        Software to prohibited countries or regions or otherwise in violation of
        any such restrictions.
      </Typography>

      <Typography paragraph>
        All matters relating to your access to or use of the Software, including
        all disputes, will be governed by the laws of the State of Texas without
        regard to any conflict of law provisions, and you hereby irrevocably
        submit to the non-exclusive jurisdiction of the courts located in the
        state of Texas and waive any objection to such exclusive jurisdiction or
        venue. Resolution of all disputes shall be conducted and resolved on an
        individual basis only, and not on a class-wide, multiple plaintiff,
        multiple claimant, consolidated or similar basis. You waive any right to
        participate in any way in a class action lawsuit against us. If
        DealerSocket takes any legal action against you due to your violation of
        the EULA, DealerSocket will be entitled to recover from you, and you
        agree to pay, all reasonable attorneys’ fees and costs of such action,
        in addition to any other relief granted to us.
      </Typography>

      <Typography paragraph>
        If any court of law, having the jurisdiction to decide on the matter,
        rules that any provision of the EULA is invalid, then that provision
        will be removed from the EULA without affecting the rest of the terms.
        The remaining provisions of the EULA will continue to be valid and
        enforceable. Failure to enforce the strict performance of any provision
        of the EULA will not constitute a waiver of DealerSocket’s right to
        subsequently enforce such provisions or any other provisions of the
        EULA. No waiver of any provision of the EULA shall be effective unless
        in writing.
      </Typography>

      <Typography paragraph>
        This EULA shall terminate upon your breach of any term contained herein.
        Upon termination, you shall immediately stop using and destroy all
        copies of the Software. You may not assign the rights granted under the
        EULA, in whole or in part and whether by operation of contract, law or
        otherwise, without DealerSocket’s prior express written consent.
      </Typography>

      <Typography paragraph>
        The EULA, the current Terms of Service, and the applicable order form or
        other agreement governing the Software are the complete and exclusive
        agreement between you and DealerSocket relating to the Software and
        supersede any previous or contemporaneous oral or written
        communications, proposals, and representations with respect to its
        subject matter. The EULA prevails over any conflicting or additional
        terms of any purchase order, ordering document, acknowledgement or
        confirmation or other document.
      </Typography>
    </div>
  );
  return (
    <Page hideTitle isEula fullscreen={xs}>
      <div className={classes.header} data-e2e="eulaTitle">
        <div className={classes.printableEulaContainer}>
          <PrintableEulaContent header={header} mainEulaContent={eulaContent} />
        </div>
        {header}
      </div>
      <div className={classes.content}>{eulaContent}</div>
      <div className={classes.agreementGroup}>
        <Form onSubmit={onSubmit}>
          <FormGroup className={classes.formGroupStyle}>
            <FormCheckboxField
              name="acceptEula"
              data-e2e="acceptEulaCheckbox"
              className={classes.formCheckbox}
              disabled={isSubmitting}
              label={
                <Typography
                  className={classes.acceptLabel}
                  variant={TypographyVariants.H6}
                  data-e2e="agreeText"
                  color="primary"
                >
                  I HAVE READ THE END USER LICENSE AGREEMENT
                </Typography>
              }
              required
            />
            <div className={classes.buttonGroup}>
              <Button
                disabled={isSubmitting}
                data-e2e="cancelButton"
                className={classes.button}
                fullWidth
                onClick={cancel}
                color="default"
              >
                Cancel
              </Button>
              <Button
                data-e2e="agreeButton"
                disabled={!acceptedEula || isSubmitting}
                className={classes.button}
                type="submit"
                color="primary"
              >
                I Agree
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </Page>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const acceptedEula = selector(state, 'acceptEula');

  return { ...state.commonForm, ...state.commonForm.dataModel, acceptedEula };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

const StyledEula = withStyles(styles)(AcceptEula);

export const AcceptEulaPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledEula);
