import { replace } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import FormGroup from '@material-ui/core/FormGroup';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StyleRules, withStyles } from '@material-ui/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { JssClasses } from '@dealersocket/ds-ui-react/types';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Form } from '../Form';
import { Page } from '../Page';
import { required, validCode } from '../../utils/validation';
import { ChevronLeftCircleIcon } from '../../icons/chevron-left-circle-icon';
import { Timer } from '../Timer/timer.component';
import * as AlertStore from '../../../store/Alert';
import * as CommonStore from '../../../store/CommonForm';

const styles = (theme: Theme): StyleRules => {
  return {
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    buttonStyle: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 12,
        padding: '10px 40px',
        whitespace: 'nowrap',
      },
      [theme.breakpoints.only('xs')]: {
        width: '55%',
        marginBottom: 20,
      },
    },
    div: {
      [theme.breakpoints.only('xs')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    formGroupStyle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
      },
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    tooltip: {
      maxWidth: 300,
    },
    typography: {
      color: theme.palette.common.white,
    },
  };
};

type VerifyProps = {
  cancel: () => void;
  disableResend: boolean;
  isInvalid: boolean;
  isSubmitting: boolean;
  loading: boolean;
  onSubmit: (values) => void;
  resendCode: () => void;
  submittedCode: string;
  timeoutEnd: number;
  verificationCode: string;
};

type InternalProps = {
  classes: JssClasses;
};

const Verify = (props: VerifyProps & InternalProps) => {
  const {
    cancel,
    disableResend,
    isInvalid,
    isSubmitting,
    loading,
    onSubmit,
    resendCode,
    submittedCode,
    timeoutEnd,
    verificationCode,
    classes,
  } = props;

  const tooltipText = (
    <Typography
      variant={TypographyVariants.Body2}
      className={classes.typography}
    >
      Verification code sent. You may send another code after{' '}
      <Timer timeoutEnd={timeoutEnd} /> have passed.
    </Typography>
  );

  const tooltip = (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={disableResend ? tooltipText : ''}
    >
      <div>
        <Button
          className={classes.secondaryButtonStyle}
          data-e2e="resendButton"
          disabled={loading || disableResend || isSubmitting}
          onClick={resendCode}
        >
          RESEND CODE
        </Button>
      </div>
    </Tooltip>
  );

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        <Typography variant={TypographyVariants.H5} paragraph>
          VERIFICATION REQUIRED
        </Typography>
        <Typography variant={TypographyVariants.Body1} paragraph>
          Type the verification code you received by email.
        </Typography>

        <FormGroup className={classes.formGroupStyle}>
          <FormTextField
            autoComplete="one-time-code"
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              spellCheck: 'false',
              style: {
                fontSize: 16,
              },
            }}
            name="verificationCode"
            required
            fullWidth
            autoFocus
            disabled={isSubmitting}
            placeholder="Enter the 6-digit code"
            validate={[
              required,
              validCode(isInvalid && submittedCode === verificationCode),
            ]}
          />
          <div className={classes.div}>
            <Button
              data-e2e="submitButton"
              disabled={
                isSubmitting ||
                !verificationCode ||
                (isInvalid && submittedCode === verificationCode)
              }
              className={classes.buttonStyle}
              type="submit"
              color="primary"
            >
              Verify
            </Button>
            {xs && tooltip}
          </div>
        </FormGroup>
      </Form>
      <div className={classes.buttonGroup}>
        <Button
          data-e2e="returnToLoginButton"
          disabled={isSubmitting}
          onClick={cancel}
        >
          <ChevronLeftCircleIcon />
          RETURN TO LOGIN
        </Button>
        {!xs && tooltip}
      </div>
    </Page>
  );
};

const StyledVerifyPage = withStyles(styles)(Verify);

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const verificationCode = selector(state, 'verificationCode');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    verificationCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const VerifyPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledVerifyPage);
