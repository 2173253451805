import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from '@dealersocket/ds-ui-react/Button';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import * as AlertStore from '../../store/Alert';
import * as CommonStore from '../../store/CommonForm';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { continueLogin } from '../../shared/utils/continue-login';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import { LandingPageUrl } from '../../shared/constants';

type VerificationSuccessProps = {
  isSubmitting: boolean;
  replace: any;
  fromLink?: boolean;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

const VerificationSuccess = (props: VerificationSuccessProps) => {
  const { isSubmitting, fromLink } = props;

  const onSubmit = () => {
    continueLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        <Typography variant={TypographyVariants.H5} paragraph>
          EMAIL VERIFIED
        </Typography>
        <Typography variant={TypographyVariants.Body1} paragraph>
          Thank you, your email address has been successfully verified.
        </Typography>
        {fromLink ? (
          <Button
            data-e2e="returnToLoginButton"
            onClick={() => window.location.replace(LandingPageUrl)}
          >
            <ChevronLeftCircleIcon />
            RETURN TO LOGIN
          </Button>
        ) : (
          <FormGroup>
            <Button
              data-e2e="continueButton"
              disabled={isSubmitting}
              type="submit"
              color="primary"
            >
              Continue
            </Button>
          </FormGroup>
        )}
      </Form>
    </Page>
  );
};

function mapStateToProps(state) {
  return { ...state.commonForm, ...state.commonForm.dataModel };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const EmailVerificationSuccessPage: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationSuccess);
