import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import * as CommonStore from '../../../store/Common';
import { Alert } from '../Alert';

function getBackgroundImage(image: number, extension: string) {
  return `url(images/bg_image${image}.${extension})`;
}

const useStyles = makeStyles({
  root: {
    height: 'calc(100vh)',
    overflow: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  bg: {
    // CSS property
    backgroundImage: (props: any) => props.backgroundImage,
  },
});

type LayoutProps = {
  backgroundImage: number;
  children: any;
  classes: any;
} & CommonStore.CommonStateType;

const LayoutCore = (props: LayoutProps) => {
  const { children, backgroundImage, webPSupported } = props;

  const styleProps = {
    backgroundImage: webPSupported
      ? getBackgroundImage(backgroundImage, 'webp')
      : getBackgroundImage(backgroundImage, 'jpg'),
  };

  const classes = useStyles(styleProps);

  return (
    <>
      <CssBaseline />
      <div className={classNames(classes.root, classes.bg)}>
        <Alert />
        {children}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

export const Layout: any = connect(mapStateToProps)(LayoutCore);
