import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { Page } from '../../shared/components/Page';
import { ChevronLeftCircleIcon } from '../../shared/icons/chevron-left-circle-icon';
import { LandingPageUrl } from '../../shared/constants';

const PasswordResetSuccess = () => {
  const params = new URLSearchParams(window.location.search);
  const returnUrl = params.get('returnUrl') || '';
  return (
    <Page>
      <Typography variant={TypographyVariants.H5} paragraph>
        PASSWORD RESET
      </Typography>
      <Typography variant={TypographyVariants.Body1} paragraph>
        You have successfully reset your password. Please return to the
        application you are trying to access to login.
      </Typography>
      <Button
        data-e2e="returnToLoginButton"
        onClick={() => window.location.replace(returnUrl || LandingPageUrl)}
      >
        <ChevronLeftCircleIcon />
        RETURN TO LOGIN
      </Button>
    </Page>
  );
};

function mapStateToProps(state) {
  return { ...state.commonForm.dataModel };
}

export const PasswordResetSuccessPage = connect(mapStateToProps)(
  PasswordResetSuccess
);
