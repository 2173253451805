export const isAssociatedProduct = (product: any) => {
  return product.type === -2 || product.type === -1;
};

export const getSessionProduct = () => {
  const sessionCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)Session\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );
  return atob(decodeURIComponent(sessionCookie)).split(':')[0];
};
