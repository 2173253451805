import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormGroup from '@material-ui/core/FormGroup';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { change, formValueSelector } from 'redux-form';
import {
  getProductDisplayName,
  getProductFromEnum,
} from '../../../shared/enums';
import { required } from '../../../shared/utils/validation';
import { Form } from '../../../shared/components/Form';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  description: {
    marginBottom: 30,
  },
  headerTitle: {
    marginBottom: 15,
  },
  passwordForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  spacerDiv: {
    flexGrow: 1,
  },
  textFieldIcon: {
    marginRight: 5,
  },
});

type ExternalProps = {
  isSubmitting: boolean;
  isUsernameFieldEmpty: boolean;
  product: any;
  onCancel: any;
  onSubmit: any;
  username: string;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators;

const ForgotPasswordComponent = (props: ExternalProps) => {
  const {
    isSubmitting,
    isUsernameFieldEmpty,
    onCancel,
    onSubmit,
    product,
    username,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(change('pageForm', 'username', username));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <div className={classes.headerTitle}>
        <Typography variant={TypographyVariants.H3}>Forgot Password</Typography>
      </div>
      <div className={classes.description}>
        <Typography paragraph>
          Enter your {getProductDisplayName(getProductFromEnum(product.type))}{' '}
          username and we&apos;ll send an account recovery code to the email
          associated with that account.
        </Typography>
      </div>
      <div className={classes.container}>
        <Form className={classes.passwordForm} onSubmit={onSubmit}>
          <FormGroup>
            <FormTextField
              autoComplete="username"
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                style: {
                  fontSize: 16,
                },
              }}
              disabled={isSubmitting}
              data-e2e="usernameField"
              name="username"
              label={`${getProductDisplayName(
                getProductFromEnum(product.type)
              )} username`}
              startAdornment={<PersonIcon className={classes.textFieldIcon} />}
              autoFocus
              validate={[required]}
              required
            />
          </FormGroup>
          <div className={classes.spacerDiv} />
          <div className={classes.buttonContainer}>
            <Button
              disabled={isSubmitting}
              data-e2e="cancelButton"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting || isUsernameFieldEmpty}
              data-e2e="sendEmailButton"
              type="submit"
              color="primary"
            >
              Send Email
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const username = selector(state, 'username');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    isUsernameFieldEmpty:
      username === null || username === undefined || username.trim() === '',
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

export const ForgotPassword: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordComponent);
