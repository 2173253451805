/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { push } from 'connected-react-router';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { withStyles, StyleRules } from '@material-ui/styles';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import { Typography } from '@dealersocket/ds-ui-react/Typography';
import { axiosApi } from '@dealersocket/react-common';
import { Page } from '../../shared/components/Page';
import { Form } from '../../shared/components/Form';
import { required } from '../../shared/utils/validation';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { defaultInputProps } from '../../shared/constants';
import * as AlertStore from '../../store/Alert';
import * as CommonFormStore from '../../store/CommonForm';
import * as CommonStore from '../../store/Common';
import { MarketingBanner } from './marketing-banner.component';

const styles = (): StyleRules => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  changeAccountsContainer: {
    marginBottom: 4,
  },
  container: {
    height: '100%',
    minHeight: 174,
  },
  divider: {
    width: '46%',
  },
  dividerDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 15,
  },
  externalProvider: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  externalProviderNoLocal: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    // ie needs defined height to flex
    height: 174,
    justifyContent: 'space-between',
    minHeight: 174,
  },
  forgotPassword: { marginRight: 10 },
  hidden: {
    display: 'none',
  },
  password: {
    marginBottom: 10,
  },
  rightButton: {
    float: 'right',
  },
  textFieldIcon: {
    marginRight: 5,
  },
  typographyDivider: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  usernameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

type LoginProps = {
  classes: any;
  isSubmitting: boolean;
  push: any;
  returnUrl: string;
  usernameValue?: string;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators &
  CommonStore.CommonStateType;

const getClientIdFromReturnUrl = (s) => {
  if (s) {
    const clientIdParam = s
      .substr(s.indexOf('?') + 1)
      .split('&')
      .filter((x) => x.includes('client_id'))
      .pop();
    if (clientIdParam) {
      return clientIdParam.split('=')[1];
    }
  }
  return null;
};

const Login = (props: LoginProps) => {
  const { classes, isSubmitting, product, usernameValue } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get('ReturnUrl');
  const clientId = getClientIdFromReturnUrl(returnUrl);

  const dispatch = useDispatch();

  const [loginReady, setLoginReady] = useState(false);
  const [providerId, setProviderId] = useState(false);
  const [allowLocalLogin, setAllowLocalLogin] = useState(true);
  const [providerDisplayName, setProviderDisplayName] = useState('');
  const [enterpriseEnabled, setEnterpriseEnabled] = useState(false);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const onOktaClick = (): any => {
    if (returnUrl != null) {
      window.location.href = `/external/login?idp=${providerId}&returnUrl=${encodeURIComponent(
        returnUrl
      )}`;
    }
  };

  const onContinueClick = () => {
    const body = {
      username: usernameValue || localStorage.getItem(`${product}_username`),
    };

    axiosHelper(
      '/account/checkUsername',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      undefined,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (typeof usernameValue === 'string') {
          localStorage.setItem(`${product}_username`, usernameValue);
        }

        setAllowLocalLogin(payload.allowLocalLogin);
        setProviderId(payload.providerId);
        setProviderDisplayName(payload.providerDisplayName);

        if (payload.needsChange) {
          window.location.reload();
        } else {
          setLoginReady(true);
          if (passwordInputRef && passwordInputRef.current) {
            passwordInputRef.current.focus();
          }
        }
      })
      .catch(() => {});
  };

  const onSubmit = (values: any): any => {
    const body = {
      ...values,
      returnUrl,
    };

    axiosHelper(
      '/account/login',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      undefined,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.push(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {});
  };

  const onClick = () => {
    let route = '/password/forgot';
    const params: string[] = [];
    if (usernameValue) {
      params.push(`username=${encodeURIComponent(usernameValue)}`);
    }
    params.push(`returnUrl=${encodeURI(window.location.href)}`);
    route = `${route}?${params.join('&')}`;
    props.push(route);
  };

  const changeAccounts = () => {
    localStorage.removeItem(`${product}_username`);
    setLoginReady(false);
    setTimeout(() => {
      if (usernameInputRef && usernameInputRef.current) {
        usernameInputRef.current.focus();
      }
    }, 100);
  };

  useEffect(() => {
    axiosApi('api/settings/isEnterpriseLogin', {})
      .then((payload) => {
        if (payload) {
          setEnterpriseEnabled(true);
          if (localStorage.getItem(`${product}_username`)) {
            dispatch(
              change(
                'pageForm',
                'username',
                localStorage.getItem(`${product}_username`)
              )
            );
            onContinueClick();
          }
        }
      })
      .catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Page clientId={clientId} banner={<MarketingBanner />}>
      <div className={classes.container}>
        <Form
          className={classes.form}
          onSubmit={
            !loginReady && enterpriseEnabled ? onContinueClick : onSubmit
          }
        >
          <FormGroup>
            <div className={classes.usernameContainer}>
              <FormTextField
                inputRef={usernameInputRef}
                autoComplete="username"
                inputProps={defaultInputProps}
                fullWidth
                disabled={isSubmitting || (loginReady && enterpriseEnabled)}
                data-e2e="usernameField"
                name="username"
                label="Username"
                startAdornment={
                  <PersonIcon className={classes.textFieldIcon} />
                }
                autoFocus
                validate={[required]}
                required
              />
              {loginReady && enterpriseEnabled && (
                <div className={classes.changeAccountsContainer}>
                  <Button
                    onClick={changeAccounts}
                    disabled={isSubmitting}
                    data-e2e="changeAccountsButton"
                  >
                    Change
                  </Button>
                </div>
              )}
            </div>
            {allowLocalLogin && (
              <FormTextField
                inputRef={passwordInputRef}
                autoComplete="current-password"
                className={
                  loginReady || !enterpriseEnabled
                    ? classes.password
                    : classes.hidden
                }
                inputProps={defaultInputProps}
                disabled={isSubmitting}
                data-e2e="passwordField"
                name="password"
                type="password"
                label="Password"
                startAdornment={
                  <VpnKeyIcon className={classes.textFieldIcon} />
                }
                validate={
                  loginReady || !enterpriseEnabled ? [required] : undefined
                }
                required
              />
            )}
          </FormGroup>
          <div className={classes.buttonContainer}>
            {(allowLocalLogin || !loginReady) && (
              <Button
                disabled={isSubmitting}
                data-e2e={
                  loginReady || !enterpriseEnabled
                    ? 'loginButton'
                    : 'continueButton'
                }
                type="submit"
                color="primary"
              >
                {loginReady || !enterpriseEnabled ? 'Login' : 'Continue'}
              </Button>
            )}
            {allowLocalLogin && (
              <Button
                disabled={isSubmitting}
                className={classes.forgotPassword}
                data-e2e="forgotPasswordButton"
                onClick={onClick}
              >
                Forgot Password
              </Button>
            )}
          </div>
        </Form>
      </div>
      {providerId && loginReady && (
        <div
          className={
            allowLocalLogin
              ? classes.externalProvider
              : classes.externalProviderNoLocal
          }
        >
          {allowLocalLogin && (
            <div className={classes.dividerDiv}>
              <Divider className={classes.divider} />
              <Typography
                className={classes.typographyDivider}
                color="textSecondary"
              >
                OR
              </Typography>
              <Divider className={classes.divider} />
            </div>
          )}
          <Button
            color="primary"
            data-e2e="OktaButton"
            disabled={isSubmitting}
            onClick={onOktaClick}
          >
            Login with {providerDisplayName}
          </Button>
        </div>
      )}
    </Page>
  );
};

const selector = formValueSelector('pageForm');
function mapStateToProps(state: any) {
  return {
    usernameValue: selector(state, 'username'),
    ...state.common,
    ...state.commonForm,
    ...state.commonForm.dataModel,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    { push, ...AlertStore.actionCreators, ...CommonFormStore.actionCreators },
    dispatch
  );
}

const StyledLogin = withStyles(styles)(Login);

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledLogin);
