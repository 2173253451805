import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@dealersocket/ds-ui-react/Button';
import { FormTextField } from '@dealersocket/ds-ui-react/form/fields/FormTextField';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { StyleRules } from '@material-ui/core/styles';
import { axiosApi } from '@dealersocket/react-common';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Timer } from '../../../shared/components/Timer/timer.component';
import { Form } from '../../../shared/components/Form';
import * as AlertStore from '../../../store/Alert';
import * as CommonFormStore from '../../../store/CommonForm';
import { getProductValue } from '../../../shared/enums';
import { useAsyncOperation } from '../../../shared/utils/use-async-operation';
import { required, validCode } from '../../../shared/utils/validation';

const styles = (theme: Theme): StyleRules => {
  return {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    description: {
      marginBottom: 30,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    headerTitle: {
      marginBottom: 15,
    },
    secondaryButtonStyle: {
      [theme.breakpoints.only('xs')]: {
        height: 40,
      },
    },
    spacerDiv: {
      flexGrow: 1,
    },
    tooltip: {
      maxWidth: 300,
    },
    whiteText: {
      color: theme.palette.common.white,
    },
  };
};

type InternalProps = {
  classes: any;
};

type ExternalProps = {
  isSubmitting: boolean;
  product: any;
  onCancel: any;
  onSubmit: any;
  username: string;
  verificationCode: string;
} & typeof AlertStore.actionCreators &
  typeof CommonFormStore.actionCreators;

const VerificationCodeInputComponent = (
  props: ExternalProps & InternalProps
) => {
  const {
    classes,
    isSubmitting,
    onCancel,
    onSubmit,
    product,
    username,
    verificationCode,
  } = props;

  const [disableResend, setDisableResend] = useState(false);
  const [timeoutEnd, setTimeoutEnd] = useState(0);
  const [lastSent, setLastSent] = useState();
  const [submittedCode, setSubmittedCode] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  async function resendVerificationCode() {
    const body = {
      username,
      product: getProductValue(product.type),
    };

    startTimer();

    return axiosApi('/accountSetup/sendForgotPasswordCode', {
      method: 'post',
      data: body,
    });
  }

  const [resendVerificationCodeFunc, busy] = useAsyncOperation(
    resendVerificationCode
  );

  const startTimer = () => {
    let lastSendTime = lastSent;
    if (!lastSendTime) {
      lastSendTime = Date.now();
      setLastSent(lastSendTime);
    }
    const lastSendIso = new Date(lastSendTime).toISOString();
    lastSendTime = Date.parse(lastSendIso) + 300000;
    const currentTime = Date.now();

    if (lastSendTime - currentTime > 0) {
      setTimeoutEnd(lastSendTime);
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
        setLastSent(null);
      }, lastSendTime - currentTime);
    }
  };
  const tooltipText = (
    <Typography
      variant={TypographyVariants.Body2}
      className={classes.whiteText}
    >
      Verification code sent. You may send another code after{' '}
      <Timer timeoutEnd={timeoutEnd} /> have passed.
    </Typography>
  );

  const tooltip = (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={disableResend ? tooltipText : ''}
    >
      <div style={{ alignSelf: 'flex-end' }}>
        <Button
          className={classes.secondaryButtonStyle}
          data-e2e="resendButton"
          disabled={busy || disableResend || isSubmitting}
          onClick={resendVerificationCodeFunc}
        >
          RESEND CODE
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className={classes.headerTitle}>
        <Typography variant={TypographyVariants.H3}>
          Enter reset code
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography paragraph>
          Enter the code that was sent to your email to link this account with
          your DealerSocket account.
        </Typography>
      </div>
      <div className={classes.container}>
        <Form
          className={classes.form}
          onSubmit={(values: any) =>
            onSubmit(values).catch(() => {
              setSubmittedCode(verificationCode);
              setIsInvalid(true);
            })
          }
        >
          <FormGroup>
            <FormTextField
              autoComplete="one-time-code"
              inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
                spellCheck: 'false',
                style: {
                  fontSize: 16,
                },
              }}
              label="Reset Code"
              name="verificationCode"
              required
              fullWidth
              autoFocus
              placeholder="Enter the 6-digit code"
              validate={[
                required,
                validCode(isInvalid && submittedCode === verificationCode),
              ]}
            />
            {tooltip}
          </FormGroup>
          <div className={classes.spacerDiv} />
          <div className={classes.buttonContainer}>
            <Button
              disabled={isSubmitting}
              data-e2e="cancelButton"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={
                isSubmitting ||
                !verificationCode ||
                (isInvalid && submittedCode === verificationCode)
              }
              data-e2e="sendEmailButton"
              type="submit"
              color="primary"
            >
              Link Accounts
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const verificationCode = selector(state, 'verificationCode');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    verificationCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AlertStore.actionCreators,
      ...CommonFormStore.actionCreators,
    },
    dispatch
  );
}

const StyledComponent = withStyles(styles)(VerificationCodeInputComponent);

export const VerificationCodeInput: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledComponent);
