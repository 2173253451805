import { Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type CommonStateType = {
  product?: string;
  webPSupported?: boolean;
};

export const reducer: Reducer<CommonStateType> = (
  state: CommonStateType | undefined
): CommonStateType => {
  // The following line guarantees that every action in the KnownAction union has been covered by a case above
  return state || { product: '', webPSupported: false };
};
