export const LandingPageUrl = 'https://www.dealersocket.com/login/';

export const defaultInputProps = {
  autoCapitalize: 'none',
  autoCorrect: 'off',
  spellCheck: 'false',
  style: {
    fontSize: 16,
  },
};
