import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChevronLeftCircleIcon = () => {
  return (
    <SvgIcon fontSize="inherit" style={{ marginRight: 8 }} color="primary">
      <path d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z" />
    </SvgIcon>
  );
};
