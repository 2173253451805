/* eslint-disable max-lines */
import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles, StyleRules } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  Typography,
  TypographyVariants,
} from '@dealersocket/ds-ui-react/Typography';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useIntl } from 'react-intl';
import * as CommonStore from '../../../store/Common';
import { SupportIcon } from '../../icons/support-icon';
import { InstagramIcon } from '../../icons/instagram-icon';
import { FacebookIcon } from '../../icons/facebook-icon';
import { TwitterIcon } from '../../icons/twitter-icon';
import { LinkedinIcon } from '../../icons/linkedin-icon';
import { DsLogo } from '../../icons/ds-logo';
import { CrmMobilePilotNotification } from '../MobileNotification/crm-mobile-pilot-notification.component';
import { getProductDisplay } from '../../enums';

const styles = (theme: Theme): StyleRules => ({
  bannerContainer: {
    display: 'none',
    '@media(min-width: 1035px)': {
      display: 'block',
    },
  },
  bottomRow: {
    padding: 60,
    [theme.breakpoints.between('sm', 'md')]: {
      padding: 50,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
    [theme.breakpoints.only('sm')]: {
      display: 'grid',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh)',
    justifyContent: 'space-between',
    minWidth: 300,
  },
  copyrightBox: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 20,
    },
  },
  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'stretch',
    },
    [theme.breakpoints.up('lg')]: {
      float: 'right',
      marginRight: '10%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      float: 'right',
      marginRight: 0,
    },
  },
  eulaPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    maxHeight: 'calc(60vh)',
    maxWidth: 'calc(50vw)',
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 'calc(90vw)',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 520,
      minHeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh)',
      maxWidth: '100%',
    },
  },
  hidden: {
    display: 'none',
  },
  hideRow: {
    display: 'none',
  },
  lightText: {
    color: '#d8d8d8',
    fontSize: 14,
  },
  link: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.common.white,
    '&:link': {
      textDecoration: 'none !important',
    },
    '&:visited': {
      textDecoration: 'none !important',
    },
    '&:active': {
      textDecoration: 'none !important',
    },
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  loginContainer: {},
  mediumText: {
    fontWeight: 400,
  },
  middleRow: {
    padding: '0 60px',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  middleRowCentered: {
    padding: 60,
    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: 50,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  paper: {
    [theme.breakpoints.up('sm')]: {
      width: 520,
    },
  },
  paperLarge: {
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
  },
  paperPadding: {
    padding: 40,
  },
  social: {
    background: theme.palette.common.white,
    borderRadius: 50,
    color: theme.palette.common.white,
    display: 'inline-block',
    fontSize: 18,
    height: 25,
    lineHeight: '30px',
    marginLeft: 10,
    opacity: 0.56,
    textAlign: 'center',
    width: 25,
  },
  socialBox: {
    alignItems: 'center',
    display: 'flex',
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
  },
  supportBox: {
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
    },
    display: 'flex',
  },
  title: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
  },
  topRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '45px 60px 60px',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '35px 50px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 20px 20px',
    },
  },
  tosDiv: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 4,
    },
  },
  whiteText: {
    color: theme.palette.common.white,
  },
});

type InternalProps = {
  classes: any;
} & CommonStore.CommonStateType;

export type PageProps = {
  children?: any;
  clientId?: string;
  fullscreen?: boolean;
  hideTitle?: boolean;
  isCentered?: boolean;
  isEula?: boolean;
  pageTitle?: React.ReactNode;
  paperSize?: string;
  banner?: React.ReactNode;
  removePadding?: boolean;
};

type PageComponentProps = InternalProps & PageProps;

const PageCore = (
  props: PageComponentProps
): React.ReactElement<PageComponentProps> => {
  const {
    classes,
    children,
    clientId,
    fullscreen,
    hideTitle,
    isCentered,
    isEula,
    paperSize,
    pageTitle,
    banner,
    product,
    removePadding,
  } = props;

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const intl = useIntl();

  const isCrmMobileHybridClient = clientId === 'crm_mobile_hybrid';
  return (
    <div className={classes.container}>
      <div className={!fullscreen ? classes.topRow : classes.hideRow}>
        <DsLogo />
        <div className={classes.supportBox}>
          <SupportIcon />
          <div style={{ marginLeft: 16 }}>
            <a
              data-e2e="supportNumber"
              href={
                intl.locale.toLowerCase() === 'en-au'
                  ? 'tel:+61395352380'
                  : 'tel:1-888-988-6444'
              }
            >
              <Typography
                variant={TypographyVariants.H6}
                className={`${classes.lightText}`}
                style={{ fontSize: 16 }}
              >
                {intl.locale.toLowerCase() === 'en-au'
                  ? '+61 3 9535 2380'
                  : '(888) 988—6444'}
              </Typography>
            </a>

            <Typography
              variant={TypographyVariants.Body2}
              className={classes.lightText}
              style={{ fontSize: 14 }}
            >
              Support
            </Typography>
          </div>
        </div>
      </div>
      <div
        className={isCentered ? classes.middleRowCentered : classes.middleRow}
      >
        <div className={classes.contentContainer}>
          <div
            data-e2e="bannerContainer"
            className={banner ? classes.bannerContainer : classes.hidden}
          >
            {isCrmMobileHybridClient ? null : banner}
          </div>
          <div className={classes.loginContainer}>
            {!hideTitle && (
              <Typography
                variant={TypographyVariants.H1}
                className={`${classes.whiteText} ${classes.title}`}
              >
                {pageTitle || (
                  <span>
                    Welcome to <strong>{getProductDisplay(product)}</strong>
                  </span>
                )}
              </Typography>
            )}
            {isCrmMobileHybridClient ? (
              <CrmMobilePilotNotification />
            ) : (
              children && (
                <Paper
                  elevation={10}
                  className={classNames(
                    isEula ? classes.eulaPaper : classes.paper,
                    {
                      [classes.paperLarge]: paperSize === 'lg',
                      [classes.paperPadding]: !removePadding,
                    }
                  )}
                  square={xs}
                >
                  {children}
                </Paper>
              )
            )}
          </div>
        </div>
      </div>
      <div className={!fullscreen ? classes.bottomRow : classes.hideRow}>
        <div className={classes.socialBox}>
          <Typography
            variant={TypographyVariants.Body2}
            className={classes.lightText}
            style={{ fontSize: 18 }}
          >
            Follow us on:
          </Typography>
          <div>
            <a
              href="https://instagram.com/dealersocket/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/DealerSocket"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/DealerSocket"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/dealersocket"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.social}
            >
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <div className={classes.copyrightBox}>
          <Typography
            data-e2e="copyright"
            variant={TypographyVariants.Body2}
            className={classes.lightText}
          >
            &copy; {new Date().getFullYear()} DealerSocket Inc. All rights
            reserved.
          </Typography>
          <div className={classes.tosDiv}>
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              data-e2e="termsOfServiceLink"
              href="https://www.dealersocket.com/terms-of-service"
            >
              Terms of Service
            </a>
            <Typography
              display="inline"
              variant={TypographyVariants.Body2}
              className={classes.lightText}
            >
              {' '}
              |{' '}
            </Typography>
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              data-e2e="privacyPolicyLink"
              href="https://www.dealersocket.com/privacy-policy"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return { ...state.commonForm.dataModel, ...state.common };
}

const StyledPage = withStyles(styles)(PageCore);

export const Page = connect(mapStateToProps)(StyledPage);
