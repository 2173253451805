import { replace } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { axiosApi } from '@dealersocket/react-common';
import { cancelLogin } from '../../shared/utils/cancel-login';
import { useAsyncOperation } from '../../shared/utils/use-async-operation';
import * as AlertStore from '../../store/Alert';
import * as CommonStore from '../../store/CommonForm';
import { axiosHelper } from '../../shared/utils/axios-helper';
import { VerifyPage } from '../../shared/components/Verify';

type VerifyUsernameProps = {
  isSubmitting: boolean;
  replace: any;
  verificationCode: string;
} & typeof AlertStore.actionCreators &
  typeof CommonStore.actionCreators;

async function getLastVerificationResendDate() {
  return axiosApi('/email/GetLastVerificationSentDate', undefined);
}

const VerifyUsername = (props: VerifyUsernameProps) => {
  const { isSubmitting, verificationCode } = props;

  const [disableResend, setDisableResend] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [submittedCode, setSubmittedCode] = useState('');
  const [timeoutEnd, setTimeoutEnd] = useState(0);

  async function resendVerificationCode() {
    return axiosApi('/accountSetup/resendUsernameVerificationCode', {
      method: 'post',
    });
  }

  const [
    resendVerificationCodeFunc,
    busy,
  ] = useAsyncOperation(resendVerificationCode, (result, error) =>
    error ? props.setAlertMessage(error) : startTimer(result)
  );

  const startTimer = (result: any) => {
    if (result && result.lastResendVerificationCodeDate) {
      const lastSendTime =
        Date.parse(result.lastResendVerificationCodeDate) + result.delay;
      const currentTime = Date.now();

      if (lastSendTime - currentTime > 0) {
        setTimeoutEnd(lastSendTime);
        setDisableResend(true);
        setTimeout(() => setDisableResend(false), lastSendTime - currentTime);
      }
    }
  };

  const [
    getLastVerificationResendDateFunc,
    loading,
  ] = useAsyncOperation(getLastVerificationResendDate, (result, error) =>
    error ? props.setAlertMessage(error) : startTimer(result)
  );

  useEffect(() => {
    getLastVerificationResendDateFunc();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onSubmit = (values) => {
    const body = {
      ...values,
    };

    axiosHelper(
      '/accountSetup/verifyUsername',
      {
        method: 'post',
        data: body,
      },
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    )
      .then((payload) => {
        if (payload.isSpaRoute) {
          props.replace(payload.redirectUrl);
        } else {
          window.location.replace(payload.redirectUrl);
        }
      })
      .catch(() => {
        setSubmittedCode(verificationCode);
        setIsInvalid(true);
      });
  };

  const cancel = () => {
    cancelLogin(
      props.setIsSubmitting,
      props.replace,
      props.setAlertMessage,
      props.clearAlertMessage
    );
  };

  return (
    <VerifyPage
      cancel={cancel}
      disableResend={disableResend}
      isInvalid={isInvalid}
      isSubmitting={isSubmitting}
      loading={busy || loading}
      onSubmit={onSubmit}
      resendCode={resendVerificationCodeFunc}
      submittedCode={submittedCode}
      timeoutEnd={timeoutEnd}
      verificationCode={verificationCode}
    />
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('pageForm');
  const verificationCode = selector(state, 'verificationCode');
  return {
    ...state.commonForm,
    ...state.commonForm.dataModel,
    verificationCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { replace, ...AlertStore.actionCreators, ...CommonStore.actionCreators },
    dispatch
  );
}

export const VerifyUsernamePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyUsername);
