import { axiosHelper } from './axios-helper';

export const cancelLogin = (
  setIsSubmitting: any,
  replace: any,
  setAlertMessage: any,
  clearAlertMessage: any
) => {
  axiosHelper(
    '/account/cancelLogin',
    {
      method: 'post',
    },
    setIsSubmitting,
    replace,
    setAlertMessage,
    clearAlertMessage
  )
    .then((payload) => {
      if (payload.isSpaRoute) {
        replace(payload.redirectUrl);
      } else {
        window.location.replace(payload.redirectUrl);
      }
    })
    .catch(() => {});
};
